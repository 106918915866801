import React from 'react'

const TextDetail = ({label, data}) => {
  return (
    <>
    <p class="font-size-16 black-color fw-bold mb-0">{label}</p>
    <p class="font-size-16 black-color">{data}</p>
    
    </>
  )
}

export default TextDetail