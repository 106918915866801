import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addInclusions, deleteInclusions, editInclusions, getInclusions } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import DeleteModal from "../../components/DeleteModal";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import Button from "../../components/elements/Button";
import { Pencil, Trash2 } from "lucide-react";
import Title from "../../components/elements/Title";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";

const Inclusions = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [icon_image, seticon_image] = useState("");
  const [inclusion, setinclusion] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type === "add") {
      setname("");
      seticon_image("");
      setId("");
    } else if (type === "edit") {
      setname(item?.name);
      setId(item?._id);
      seticon_image(item?.imageName);
    } else {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const inclusionDelete = async (id) => {
    try {
      await dispatch(deleteInclusions(id)).unwrap();
      setIsOpen(false);
      setFilteredData((prev) => prev?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Inclusions = async () => {
    try {
      setload(true);
      setinclusion(null);
      setFilteredData(null);
      const response = await dispatch(getInclusions()).unwrap();
      setinclusion(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Inclusions();
  }, []);



  const addandEditInclusion = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("inclusionImage", icon_image);
      if (id) {
        await dispatch(editInclusions(data)).unwrap();
      } else {
        await dispatch(addInclusions(data)).unwrap();
      }
      closeModal()
      Inclusions();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const [nameFilter, setnameFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = inclusion?.filter(item => {
      const category = item?.name.toLowerCase();
      return (
        (nameFilter === '' || category.includes(nameFilter.toLowerCase()))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };

  const resetFilter = () => {
    setFilteredData(inclusion)
    setnameFilter('')
    settoggle(false);
  };


  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {(modalType === "add" || modalType === "edit") ? (
          <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Inclusion`} closeModal={closeModal} onClick={addandEditInclusion} buttonText={modalType === "add" ? "Submit" : "Update"}>
            <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Inclusion Name" bottomSpace={3} />
            <FloatingInput type="file" onChange={(e) => seticon_image(e.target.files[0])} placeholder="Icon" />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => inclusionDelete(id)} label="Inclusion" />
        ) : null}
      </CustomModal>

      <ContentSection title="Inclusions">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Inclusions', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Inclusions</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Inclusion Name" />
                <label for="att-name">Inclusion Name</label>
              </div>
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button class="btn cw-btn cw-yellow-btn px-3" onClick={() => viewModal("", "add")}>Add Inclusion</Button>
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Inclusions : {inclusion?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Icon</th>
                <th scope="col">Created By</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.imageName ? "Yes" : "No"}</td>
                  <td>{item?.createdBy?.name}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Inclusions