import React, { useEffect, useState } from 'react';

const DropdownSearch = ({ items, label, placeholder, onItemSelect, topSpace = 0, bottomSpace = 0, className, valueName }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    if (valueName) {
      setSearchValue(valueName);
    }
  }, [valueName]);

  const filteredItems = items?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setShowDropdown(value.length > 0);
  };

  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    onItemSelect(data);
    setShowDropdown(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredItems.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredItems[highlightedIndex]);
      }
    }
  };
  const validTopSpace = Math.min(Math.max(topSpace, 0), 5);
  const validBottomSpace = Math.min(Math.max(bottomSpace, 0), 5);

  return (
    
    <div className={`mt-${validTopSpace} mb-${validBottomSpace} ${className}`}>
      <div className="form-floating cw-input">
        <input
          type="text"
          className="form-control"
          onKeyDown={handleKeyDown}
          maxLength={120}
          onChange={handleInputChange}
          value={searchValue}
          id={label.toLowerCase()}
          placeholder={placeholder}
          autoComplete="off"
        />
        <label htmlFor={label.toLowerCase()}>{label}</label>
      </div>

      {showDropdown && (
        <div className="dropdown cw-select-dropdown">
          <ul className="dropdown-menu show" style={{ width: '100%' }}>
            {filteredItems?.map((data, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(data)}
                className={highlightedIndex === index ? 'highlighted' : ''}
              >
                <a className="dropdown-item">{data?.name}</a>
              </li>
            ))}
            {items?.length > 0 && filteredItems?.length === 0 && (
              <li>
                <a className="dropdown-item">
                  <i className="hasNoResults">No matching results</i>
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
