import React from 'react'

const ImageMessage = ({ data }) => {
    return (
        <>
            <div className="sm-message-text">
                <div className="d-flex flex-wrap gap-2 sm-message-images">
                    {data?.attachment?.slice(0, 4).map((imageUrl, index) => (
                        <div key={index} className={`sm-message-image sm-message-image-${data?.attachment?.length}`}>
                            <img src={`${process.env.REACT_APP_IMGURL}/w_500/q_auto/f_auto/${imageUrl}`} alt={`media-${index}`} />
                        </div>
                    ))}
                    {data?.attachment.length > 4 && (
                        <div className="sm-message-image sm-image-count">
                            <span>+{data?.attachment?.length - 4}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ImageMessage