import React from 'react'

const Button = ({ 
  className = "", 
  variant = "yellow", 
  fullwidth = false, 
  size = "", 
  loading = false, 
  onClick, 
  type = "button", 
  disabled = false, 
  children 
}) => {
  return (
    
    <button 
      className={`btn cw-btn cw-${variant}-btn ${fullwidth ? "w-100" : ""} ${size === "small" ? "px-4 py-2" : "" } ${className}`} 
      type={type} onClick={onClick} disabled={disabled}>
      {loading 
        ? <div className="loading-dots">
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
          </div>
        : children
      }
    </button>
  );
}

export default Button;
