import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addAdmin, editAdmin, } from "../../store/apiSlice";
import Spinner from "../../components/Spinner";
import FloatingInput from "../../components/elements/FloatingInput";
import SelectButton from "../../components/elements/SelectButton";
import Button from "../../components/elements/Button";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";

const AddAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const access = ["read", "write", "delete"]
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [imageName, setimageName] = useState();
  const [space_access, setspace_access] = useState([]);
  const [blogs_access, setblogs_access] = useState([]);
  const [users_access, setusers_access] = useState([]);
  const [system_access, setsystem_access] = useState([]);
  const [load, setload] = useState(false);

  useEffect(() => {
    if (location?.state?._id) {
      setId(location?.state?._id?._id)
      setname(location?.state?._id?.name)
      setemail(location?.state?._id?.email)
      setimageName(location?.state?._id?.imageName)
      setspace_access(location?.state?._id?.space_access)
      setblogs_access(location?.state?._id?.blogs_access)
      setusers_access(location?.state?._id?.users_access)
      setsystem_access(location?.state?._id?.system_access)
    }
    else {
      setId("")
      setname("")
      setemail("")
      setimageName("")
      setspace_access([])
      setblogs_access([])
      setusers_access([])
      setsystem_access([])
    }
  }, []);


  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    setspace_access((prevStoresAccess) => {
      if (isChecked) {
        if (!prevStoresAccess.includes(value)) {
          return [...prevStoresAccess, value];
        }
      } else {
        return prevStoresAccess.filter((item) => item !== value);
      }
      return prevStoresAccess;
    });
  };

  const handleCheckboxChange1 = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    setblogs_access((prevStoresAccess) => {
      if (isChecked) {
        if (!prevStoresAccess.includes(value)) {

          return [...prevStoresAccess, value];
        }
      } else {

        return prevStoresAccess.filter((item) => item !== value);
      }

      return prevStoresAccess;
    });
  };

  const handleCheckboxChange2 = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    setusers_access((prevStoresAccess) => {
      if (isChecked) {
        if (!prevStoresAccess.includes(value)) {

          return [...prevStoresAccess, value];
        }
      } else {

        return prevStoresAccess.filter((item) => item !== value);
      }

      return prevStoresAccess;
    });
  };

  const handleCheckboxChange3 = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    setsystem_access((prevStoresAccess) => {
      if (isChecked) {
        if (!prevStoresAccess.includes(value)) {

          return [...prevStoresAccess, value];
        }
      } else {

        return prevStoresAccess.filter((item) => item !== value);
      }

      return prevStoresAccess;
    });
  };

  const addandEditSubAdmin = async (e) => {
    setload(true)
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", name)
      formData.append("email", email)
      formData.append("password", password)
      formData.append("adminImage", imageName)
      formData.append("space_access", JSON.stringify(space_access))
      formData.append("blogs_access", JSON.stringify(blogs_access))
      formData.append("users_access", JSON.stringify(users_access))
      formData.append("system_access", JSON.stringify(system_access))

      if (id) {
        formData.append("id", id)
        await dispatch(editAdmin(formData)).unwrap();
      } else {
        await dispatch(addAdmin(formData)).unwrap();
      }
      navigate("/admins")
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
    <>
      {load && <Spinner />}
      <ContentSection title={`${location.pathname === "/add-admin" ? "Add" : "Edit"} Admin User`}>
        <Breadcrumb items={[{ label: 'Menu', }, { label: `${location.pathname === "/add-admin" ? "Add" : "Edit"} Admin User`, active: true },]} />
        <Title bottomSpace={2}>{location.pathname === "/add-admin" ? "Add" : "Edit"} Admin User</Title>
        <BoxSection bottomSpace={4}>
          <div className="row gy-3">
            <div className="col-md-6">
              <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Email" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="password" value={password} onChange={(e) => setpassword(e.target.value)} placeholder="Password" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="file" onChange={(e) => setimageName(e.target.files[0])} placeholder="Profile Image" />
            </div>
            <div className="col-md-12">
              <p class="font-size-20 pink-color dm-font mb-2">Spaces Access</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {access.map((e, i) => (
                  <SelectButton key={i} name="store-access" value={e} checked={space_access.includes(e)} onChange={handleCheckboxChange} type="checkbox" id={`${e}-space`} label={e.charAt(0).toUpperCase() + e.slice(1)} />
                ))}
              </div>

              <p class="font-size-20 pink-color dm-font mb-2">Blogs Access</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {access.map((e, i) => (
                  <SelectButton key={i} name="blog-access" value={e} checked={blogs_access.includes(e)} onChange={handleCheckboxChange1} type="checkbox" id={`${e}-blog`} label={e.charAt(0).toUpperCase() + e.slice(1)} />
                ))}
              </div>

              <p class="font-size-20 pink-color dm-font mb-2">Users Access</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {access.map((e, i) => (
                  <SelectButton key={i} name="user-access" value={e} checked={users_access.includes(e)} onChange={handleCheckboxChange2} type="checkbox" id={`${e}-user`} label={e.charAt(0).toUpperCase() + e.slice(1)} />
                ))}
              </div>

              <p class="font-size-20 pink-color dm-font mb-2">System Access</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {access.map((e, i) => (
                  <SelectButton key={i} name="system-access" value={e} checked={system_access.includes(e)} onChange={handleCheckboxChange3} type="checkbox" id={`${e}-system`} label={e.charAt(0).toUpperCase() + e.slice(1)} />
                ))}
              </div>

            </div>
          </div>
        </BoxSection>

        <div class="d-flex justify-content-center gap-2">
          <Button onClick={() => navigate("/admins")} variant="black-outline" size="small">Cancel</Button>
          <Button onClick={addandEditSubAdmin} size="small">{location.pathname === "/add-admin" ? "Create" : "Update"}</Button>
        </div>
      </ContentSection>
    </>
  )
}

export default AddAdmin