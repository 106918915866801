import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addFeatureCategory, deleteFeatureCategory, editFeatureCategory, getFeatureCategories } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import DeleteModal from "../../components/DeleteModal";
import Button from "../../components/elements/Button";
import { Pencil, Trash2 } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";


const FeatureCategories = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [featurecategories, setfeaturecategories] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type === "add") {
      setname(item);
      setId(item);
    } else if (type === "edit") {
      setname(item.name);
      setId(item?._id);
    } else {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const featureCategoryDelete = async (id) => {
    try {
      await dispatch(deleteFeatureCategory(id)).unwrap();
      setIsOpen(false);
      setfeaturecategories((prev) => prev?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const FeatureCategory = async () => {
    try {
      setload(true);
      setfeaturecategories(null);
      const response = await dispatch(getFeatureCategories()).unwrap();
      setfeaturecategories(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    FeatureCategory();
  }, []);


  const addandEditFeatureCategory = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        id: id,
        name: name,
      }
      if (id) {
        await dispatch(editFeatureCategory(payload)).unwrap();
      } else {
        await dispatch(addFeatureCategory(payload)).unwrap();
      }
      FeatureCategory();
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
    <>

      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {(modalType === "add" || modalType === "edit") ? (
          <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Inclusion`} closeModal={closeModal} onClick={addandEditFeatureCategory} buttonText={modalType === "add" ? "Submit" : "Update"}>
            <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Feature Category Name" />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => featureCategoryDelete(id)} label="Feature Category" />
        ) : null}
      </CustomModal>


      <ContentSection title="Feature Categories">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Feature Categories', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Feature Categories</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button onClick={() => viewModal("", "add")}>Add Feature Category</Button>
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Feature Categories : {featurecategories?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Category Name</th>
                <th scope="col">Created By</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : featurecategories?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.createdBy?.name}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default FeatureCategories