import React from 'react'

const ContentSection = ({ title, children }) => {
    document.title = `${title} - The Coworking Spaces Admin`
    return (
        <div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
            {children}
        </div>
    )
}

export default ContentSection