import React, { useEffect } from 'react'

const Pagination = ({currentPage, handlePageChange, totalPages}) => {

    const renderPaginationLinks = () => {
        const pages = [];
        const maxPagesToShow = 5; 
      
        if (totalPages <= maxPagesToShow) {
          for (let page = 1; page <= totalPages; page++) {
            pages.push(renderPageLink(page));
          }
        } else {
          let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
          const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
      
          if (endPage === totalPages) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
          }
      
          pages.push(renderPageLink(1));
      
          if (startPage > 2) {
            pages.push(renderEllipsis());
          }
      
          for (let page = startPage === 1 ? 2 : startPage; page <= endPage; page++) {
            pages.push(renderPageLink(page));
          }
      
          if (endPage < totalPages - 1) {
            pages.push(renderEllipsis());
          }
      
          if (endPage !== totalPages) {
            pages.push(renderPageLink(totalPages));
          }
        }
      
        return pages;
    };
       
    const renderPageLink = (page) => (
    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
        <a className="page-link" onClick={() => handlePageChange(page)}>
        {page}
        </a>
    </li>
    );
    
    const renderEllipsis = () => (
    <li key="ellipsis" className="page-item disabled">
        <span className="page-link">...</span>
    </li>
    );

    useEffect(() => {
        renderPaginationLinks()
      }, [totalPages]);

  return (
    <>
    <div className="cw-pagination" aria-label="Page navigation example">
    <ul className="pagination">
      <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
        <a className="page-link" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      {renderPaginationLinks()}
      <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
        <a className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
    </div>
    
    </>
  )
}

export default Pagination