import React from 'react'

const QuoteMessage = ({ data, isCurrentUser }) => {
    return (
        <>
            {(data?.quoteReply?.userId?._id || data?.quoteReply?.adminId?._id) ?
                <div className="sm-message-text" >
                    <h3 className="font-size-20 fw-bold">Offer ({data?.userRequirement?.requirementNo})</h3>
                    <p className="font-size-16 mb-1"><b>No Of People:</b> {data?.quoteReply?.noOfPeopleResponse}</p>
                    <p className="font-size-16 mb-1 text-break"><b>Comment:</b> {data?.quoteReply?.comment}</p>
                    <p className="font-size-16 mb-1 text-break"><b>Price:</b></p>
                    {data?.quoteReplyPrice?.map((data, index) => (
                        <p key={index} className="font-size-16 mb-0">{data?.type} {data?.amount}</p>
                    ))}
                </div>
                :
                <div className="sm-message-text" >
                    <h3 className="font-size-20 fw-bold">Quotation ({data?.userRequirement?.requirementNo})</h3>
                    <p className="font-size-16 mb-1"><b>For how many People:</b> {data?.quote?.noOfPeople}</p>
                    <p className="font-size-16 mb-1"><b>For How long:</b> {data?.quote?.duration}</p>
                    <p className="font-size-16 mb-1"><b>What Are You Looking For:</b> {data?.roomdesktypes?.name}</p>
                    {data?.joiningTime && <p className="font-size-16 mb-1"><b>When Joining:</b> {data?.quote?.joiningTime}</p>}
                    <p className="font-size-16 mb-0"><b>Space Name:</b> {data?.quote?.spaceId?.name}</p>
                </div>}
        </>
    )
}

export default QuoteMessage