import moment from "moment";
import Button from "../../components/elements/Button";
import FloatingInput from "../../components/elements/FloatingInput";
import FloatingTextArea from "../../components/elements/FloatingTextArea";
import HtmlTextEditor from "../../components/elements/HtmlTextEditor";
import Spinner from "../../components/Spinner";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { addBlogs, editBlogs, getAllCountries, getBlogCategory, getBlogs } from "../../store/apiSlice";
import generateSlug from "../../utils/GenerateSlug";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setload] = useState(false);

  const [blogcategories, setblogcategories] = useState(null);
  const [countries, setcountries] = useState([]);
  const [publishdate, setpublishdate] = useState("");
  const [title, settitle] = useState("");
  const [excerpt, setexcerpt] = useState("");
  const [blogcategory, setblogcategory] = useState("");
  const [author, setauthor] = useState("The Coworking Spaces");
  const [image, setimage] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [description, setdescription] = useState("");
  const [slug, setslug] = useState("");
  const [country, setcountry] = useState(null);

  const handleChange = (html) => {
    setdescription(html);
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const BlogCategories = async () => {
    try {
      setblogcategories(null);
      const response = await dispatch(getBlogCategory()).unwrap();
      setblogcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    BlogCategories();
    Countries();
  }, []);

  useEffect(() => {
    if (title && !editBlog) {
      const slugifiedName = generateSlug(title);
      setslug(slugifiedName);
    }
  }, [title]);


  const addandEditBlog = async (e) => {
    e.preventDefault();
    try {
      setload(true);
      if (!title) {
        toast.error('Blog Title is required');
        setload(false);
        return;
      }
      if (!excerpt) {
        toast.error('Excerpt is required');
        setload(false);
        return;
      }
      if (!blogcategory) {
        toast.error('Category is required');
        setload(false);
        return;
      }
      if (!image) {
        toast.error('Feature Image is required');
        setload(false);
        return;
      }
      if (!description) {
        toast.error('Blog Content is required');
        setload(false);
        return;
      }
      const formData = new FormData();
      formData.append("title", title)
      formData.append("excerpt", excerpt)
      formData.append("categoryId", blogcategory)
      formData.append("publishdate", publishdate)
      formData.append("author", author ? author : "The CoWorking Spaces")
      formData.append("blogImage", image)
      formData.append("description", description)
      formData.append("slug", slug)
      if (country) formData.append("countryId", country)

      if (editBlog) {
        formData.append("id", blogId)
        await dispatch(editBlogs(formData)).unwrap();
      } else {
        await dispatch(addBlogs(formData)).unwrap();
      }
      setload(false);
      navigate('/blogs')
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };


  const [editBlog, seteditBlog] = useState(null);
  const urlParts = window.location.pathname.split('/');
  const blogId = urlParts[urlParts.length - 1];

  const EditBlog = async () => {
    try {
      setload(true);
      seteditBlog(null);
      const response = await dispatch(getBlogs()).unwrap();
      const blog = response?.data?.find(blog => blog?._id === blogId);
      seteditBlog(blog)
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (blogId !== "add-blog") {
      EditBlog();
    }
  }, [])

  useEffect(() => {
    if (editBlog) {
      settitle(editBlog?.title);
      setpublishdate(editBlog?.publishdate);
      setblogcategory(editBlog?.categoryId?._id);
      setexcerpt(editBlog?.excerpt);
      setauthor(editBlog?.author);
      setdescription(editBlog?.description);
      setimage(editBlog?.image);
      setslug(editBlog?.slug);
      setcountry(editBlog?.countryId)
      if (editBlog?.countryId) {
        setSelectedOption("No")
        const FindCountry = countries?.find(item => item?._id === editBlog?.countryId)
        setSearchValue2(FindCountry?.name)
      }
    }
  }, [editBlog, location.pathname]);


  useEffect(() => {
    if (editBlog) {
      document.title = editBlog?.title
    }

  }, [editBlog, location.pathname]);

  console.log(editBlog, "editBlog");
  console.log(blogId, "blogId");

  const [showDropdown2, setShowDropdown2] = useState(false);
  const [searchValue2, setSearchValue2] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue2(value);
    if (value.length > 0) {
      setShowDropdown2(true);
    } else {
      setShowDropdown2(false);
    }
  };
  const handleOptionClick = (data) => {
    setSearchValue2(data?.name);
    setcountry(data?._id);
    setShowDropdown2(false);
  };

  const filteredCountries = countries?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };

  const [selectedOption, setSelectedOption] = useState('Yes');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      {load && <Spinner />}
      <ContentSection title={`${editBlog ? "Edit" : "Add"} Blog`}>
        <Breadcrumb items={[{ label: 'Menu', }, { label: `${editBlog ? "Edit" : "Add"} Blog`, active: true },]} />
        <Title bottomSpace={2}>{editBlog ? "Edit" : "Add"} Blog</Title>

        <BoxSection bottomSpace={4}>
          <FloatingInput value={title} onChange={(e) => settitle(e.target.value)} placeholder="Blog Title" bottomSpace={3} />
          {editBlog &&
            <FloatingInput value={slug} onChange={(e) => setslug(e.target.value)} placeholder="Blog Slug" bottomSpace={3} />}
          <FloatingTextArea value={excerpt} onChange={(e) => setexcerpt(e.target.value)} placeholder="Excerpt" bottomSpace={3} />
          <div className="row gy-3 mb-3">
            <div className="col-md-3">
              <div class="dropdown cw-select-dropdown">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {blogcategory ? blogcategories?.find(user => user?._id === blogcategory)?.name : "Blog Category"}
                </a>
                <ul class="dropdown-menu">
                  {blogcategories?.map((c, i) => (
                    <li key={i}><a onClick={(e) => setblogcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <FloatingInput type="file" onChange={(e) => setimage(e.target.files[0])} placeholder="Featured Image" />
            </div>
            <div className="col-md-3">
              <FloatingInput type="date" min={getCurrentDate()} value={publishdate} onChange={(e) => setpublishdate(e.target.value)} placeholder="Publish Date" />
            </div>
            <div className="col-md-3">
              <select value={selectedOption} onChange={handleOptionChange} className="form-select py-3 cw-select-field" style={{ paddingLeft: '10px', height: 'fit-content' }}>
                <option value="">Want to make Blog Global ?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            {selectedOption == "No" && <div className="col-md-3">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue2} id="country" placeholder="country" autoComplete="off" />
                <label for="country">Country</label>
              </div>
              {showDropdown2 && (
                <div className="dropdown cw-select-dropdown">
                  <ul className="dropdown-menu show" style={{ width: '100%' }}>
                    {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.map((data, index) => (
                      <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                    ))}
                    {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.length === 0 && (
                      <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                    )}
                  </ul>
                </div>
              )}
            </div>}
          </div>
          <HtmlTextEditor value={description} onChange={handleChange} label="Blog Content" />

          {editBlog && (<p className="font-size-16 fw-semibold black-color mb-0">Last Updated: {moment(editBlog.updatedAt).format("DD-MM-YYYY")}</p>)}
        </BoxSection>

        <div class="d-flex justify-content-center gap-2">
          <Button onClick={() => navigate("/blogs")} variant="black-outline" size="small">Cancel</Button>
          <Button onClick={addandEditBlog} size="small" disabled={load}>Submit</Button>
        </div>
      </ContentSection>
    </>
  )
}

export default AddBlog