import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getProfile } from "../../store/apiSlice";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import DeleteModal from "../../components/DeleteModal";
import TextDetail from "../../components/elements/TextDetail";
import ModalContent from "../../components/elements/ModalContent";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import moment from "moment/moment";
import { Eye, Trash2 } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";


const Users = () => {
  const profile = useSelector(getProfile)
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [users, setusers] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type === "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type === "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const userDelete = async (id) => {
    try {
      // await dispatch(({ id: id })).unwrap();
      setIsOpen(false);
      Users();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const UsersList = async () => {
    try {
      setload(true);
      setusers(null);
      setFilteredData(null);
      const response = await dispatch(getAllUsers()).unwrap();
      setusers(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    UsersList();
  }, []);

  const [nameFilter, setnameFilter] = useState('');
  const [emailFilter, setemailFilter] = useState('');
  const [googleSignupCheck, setgoogleSignupCheck] = useState(false);

  const applyFilter = () => {
    const filteredResult = users?.filter(item => {
      const name = item?.name?.toLowerCase();
      const email = item?.email?.toLowerCase();
      return (
        (nameFilter === '' || name?.toLowerCase()?.includes(nameFilter?.toLowerCase())) &&
        (emailFilter === '' || email?.toLowerCase()?.includes(emailFilter?.toLowerCase())) &&
        (!googleSignupCheck || item?.user_social_type === "google.com")
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };


  const resetFilter = () => {
    setemailFilter('');
    setnameFilter('');
    setgoogleSignupCheck(false)
    setFilteredData(users)
    settoggle(false);
  };

  return (
    <>

      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Customer Detail" closeModal={closeModal}>
            <TextDetail label="Name" data={userDetail?.name} />
            <TextDetail label="Email" data={userDetail?.email} />
            <TextDetail label="Phone" data={userDetail?.phone} />
            <TextDetail label="Role" data={userDetail?.role} />
            <TextDetail label="Status" data={userDetail?.status} />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => userDelete(id)} label="User" />
        ) : null}
      </CustomModal>

      <ContentSection title="Business Users">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Business Users', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Business Users</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="user-name" placeholder="user Name" />
                <label for="user-name">Name</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="email" onChange={(e) => setemailFilter(e.target.value)} value={emailFilter} class="form-control" id="user-email" placeholder="User Email" />
                <label for="user-email">Email</label>
              </div>
              <div class="mb-3 sp-input-filter">
                <p class="font-size-20 fw-semibold mb-2">Check Filter</p>
                <div class="d-flex gap-2 flex-wrap">
                  <input type="checkbox" class="btn-check" id="google-check" checked={googleSignupCheck} onChange={() => setgoogleSignupCheck(!googleSignupCheck)} />
                  <label class="btn cw-select-btn" for="google-check">Google</label>
                </div>
              </div>
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">

          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Business Users : {filteredData?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th style={{ width: '140px' }} scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.email?.substring(0, 30)}</td>
                  <td>{item?.role}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons`}><Eye width="18" /></div>
                      {profile?.system_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons`}><Trash2 width="18" /></div>)}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Users