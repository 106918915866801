import { DownloadCloud, FileVideo } from 'lucide-react'
import React, { useState } from 'react'

const VideoMessage = ({ data, isCurrentUser }) => {
    const [downloading, setDownloading] = useState(false);

    const handleDownload = async () => {
        if (!data?.attachment?.[0]) return;

        setDownloading(true);
        const fileUrl = `https://res.cloudinary.com/dayfv4et9/video/upload/${data.attachment[0]}`;

        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = data.attachment[0]; // Default filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        } finally {
            setDownloading(false);
        }
    };
    return (
        <>
            <div className="sm-message-text">
                <div className="d-flex gap-2 sm-message-media">
                    <FileVideo width={22} height={22} stroke={isCurrentUser ? "#000000" : "#ffffff"} />
                    <div style={{ marginRight: '10px' }}>
                        <p className="font-size-14 mb-0 black-color fw-semibold"><a href={`https://res.cloudinary.com/dayfv4et9/video/upload/${data?.attachment[0]}`} target="_blank" download className={isCurrentUser ? "black-color" : "white-color"}>{data?.attachment[0]}</a></p>
                        <p style={{ color: '#646464' }} className="font-size-12 mb-0">Video</p>
                    </div>
                    <div className="cursor-pointer" onClick={handleDownload}>
                        {downloading ? (
                            <span className="font-size-12">Downloading...</span>
                        ) : (
                            <DownloadCloud width={22} height={22} stroke={"#646464"} />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoMessage