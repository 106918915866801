import { useDispatch, useSelector } from "react-redux";
import { getNewsletterEmails, getProfile } from "../../store/apiSlice";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import DeleteModal from "../../components/DeleteModal";
import moment from "moment";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";


const NewsletterEmails = () => {
  const profile = useSelector(getProfile)
  const dispatch = useDispatch();
  const [newsletterEmails, setnewsletterEmails] = useState(null);
  const [load, setload] = useState(false);

  const [id, setId] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const viewModal = async (item, type) => {
    setIsOpen(true);
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const newsletterDelete = async (id) => {
    try {
      // await dispatch(deleteNewsletterEmail(id)).unwrap();
      setIsOpen(false);
      setnewsletterEmails((prev) => prev?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const EmailForNewsletter = async () => {
    try {
      setload(true);
      setnewsletterEmails(null);
      const response = await dispatch(getNewsletterEmails()).unwrap();
      setnewsletterEmails(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    EmailForNewsletter();
  }, []);


  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => newsletterDelete(id)} label="Newsletter Email" />
        ) : null}
      </CustomModal>


      <ContentSection title="Newsletter Email">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Newsletter Email', active: true },]} />
        <Title bottomSpace={2}>Newsletter Email</Title>

        <BoxSection>
          <p className="font-size-20 pink-color">Newsletter Email : {newsletterEmails?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Email</th>
                <th style={{ width: '140px' }} scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : newsletterEmails?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.email}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default NewsletterEmails