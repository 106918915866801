import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addFeature, deleteFeature, editFeature, getFeatureCategories, getFeatures } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import DeleteModal from "../../components/DeleteModal";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import Button from "../../components/elements/Button";
import { Pencil, Trash2 } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";


const Features = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [features, setfeatures] = useState(null);
  const [categories, setcategories] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [featureName, setfeatureName] = useState("");
  const [category, setcategory] = useState("");
  const [imageName, setimageName] = useState("");
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId();
      setfeatureName();
      setcategory();
      setimageName();
    } else if (type === "edit") {
      setUserDetail(item);
      setfeatureName(item?.name)
      setId(item?._id);
      setcategory(userDetail?.featureCategoryId?._id)
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const featureDelete = async (id) => {
    try {
      await dispatch(deleteFeature(id)).unwrap();
      setIsOpen(false);
      setFilteredData((prevFeatures) => prevFeatures?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Features = async () => {
    try {
      setload(true);
      setfeatures(null);
      setFilteredData(null);
      const response = await dispatch(getFeatures()).unwrap();
      setfeatures(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const FeatureCategories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getFeatureCategories()).unwrap();
      setcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    Features();
    FeatureCategories()
  }, []);


  const addandEditfeature = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", featureName);
      data.append("featureCategoryId", category);
      data.append("featureImage", imageName);
      if (id) {
        await dispatch(editFeature(data)).unwrap();
      } else {
        await dispatch(addFeature(data)).unwrap();
      }
      setIsOpen(false);
      Features();
      setId("")
      Features();
      setfeatureName("")
      setcategory("")
      setimageName("")
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const [nameFilter, setnameFilter] = useState('');
  const [categoryFilter, setcategoryFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = features?.filter(item => {
      const name = item?.name.toLowerCase();
      const category = item?.featureCategoryId?.name.toLowerCase();

      return (
        (nameFilter === '' || name.includes(nameFilter.toLowerCase())) &&
        (categoryFilter === '' || category.includes(categoryFilter.toLowerCase()))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };

  const resetFilter = () => {
    setFilteredData(features)
    setnameFilter('')
    setcategoryFilter('')
    settoggle(false);
  };


  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {(modalType === "add" || modalType === "edit") ? (
          <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Feature`} closeModal={closeModal} onClick={addandEditfeature} buttonText={modalType === "add" ? "Submit" : "Update"}>
            <FloatingInput value={featureName} onChange={(e) => setfeatureName(e.target.value)} placeholder="Feature Name" bottomSpace={3} />
            <FloatingInput type="file" onChange={(e) => setimageName(e.target.files[0])} placeholder="Icon" bottomSpace={3} />
            <div class="dropdown cw-select-dropdown">
              <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {category ? categories.find(user => user?._id === category)?.name : "Category"}
              </a>
              <ul class="dropdown-menu">
                {categories?.map((c, i) => (
                  <li key={i}><a onClick={(e) => setcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
                ))}
              </ul>
            </div>
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => featureDelete(id)} label="Feature" />
        ) : null}
      </CustomModal>

      <ContentSection title="Features">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Features', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Features</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Feature Name" />
                <label for="att-name">Feature Name</label>
              </div>
              <div class="dropdown cw-select-dropdown mb-3">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {categoryFilter ? categoryFilter : "Category"}
                </a>
                <ul class="dropdown-menu">
                  {categories?.map((c, i) => (
                    <li key={i}><a onClick={(e) => setcategoryFilter(c?.name)} class="dropdown-item">{c?.name}</a></li>
                  ))}
                </ul>
              </div>
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button onClick={() => viewModal(null, "add")}>Add Feature</Button>
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Features : {features?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Category</th>
                <th scope="col">Icon</th>
                <th scope="col">Created By</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.featureCategoryId?.name}</td>
                  <td><img src={`${process.env.REACT_APP_IMGURL}/${item?.imageName}`} width={50} alt="" /></td>
                  <td>{item?.createdBy?.name}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Features