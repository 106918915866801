import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addBlogCategory, deleteBlogCategory, editBlogCategory, getBlogCategory } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import DeleteModal from "../../components/DeleteModal";
import { Pencil, Trash2 } from "lucide-react";
import Button from "../../components/elements/Button";
import Breadcrumb from "../../components/elements/Breadcrumb";
import ContentSection from "../../components/elements/ContentSection";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";


const BlogCategories = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [blogs, setblogs] = useState(null);
  const [icon_image, seticon_image] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type === "add") {
      setname(item);
      seticon_image(item);
      setId(item);
    } else if (type === "edit") {
      setname(item.name);
      seticon_image(item?.imageName);
      setId(item?._id);
    } else {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const blogDelete = async (id) => {
    try {
      await dispatch(deleteBlogCategory(id)).unwrap();
      setIsOpen(false);
      setblogs((prevBlogs) => prevBlogs?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Blogs = async () => {
    try {
      setload(true);
      setblogs(null);
      const response = await dispatch(getBlogCategory()).unwrap();
      setblogs(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Blogs();
  }, []);


  const addandEditBlogCategory = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("blogCategoryImage", icon_image);

      if (id) {
        await dispatch(editBlogCategory(data)).unwrap();
      } else {
        await dispatch(addBlogCategory(data)).unwrap();
      }
      closeModal()
      Blogs();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {(modalType === "add" || modalType === "edit") ? (
          <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Inclusion`} closeModal={closeModal} onClick={addandEditBlogCategory} buttonText={modalType === "add" ? "Submit" : "Update"}>
            <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Blog Category Name" bottomSpace={3} />
            <FloatingInput type="file" onChange={(e) => seticon_image(e.target.files[0])} placeholder="Blog Category Icon" />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => blogDelete(id)} label="Blog Category" />
        ) : null}
      </CustomModal>

      <ContentSection title="Blog Categories">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Blogs', link: "/blogs" }, { label: 'Blog Categories', active: true },]} />

        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Blog Categories</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">

          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button onClick={() => viewModal("", "add")}>Add Blog Category</Button>
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Blog Categories : {blogs?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Category Name</th>
                <th scope="col">Icon</th>
                <th scope="col">Created By</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '60px' }} className='w-100 shimmer'></div></td></tr>)) : blogs?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td><img src={`${process.env.REACT_APP_IMGURL}/${item?.imageName}`} width={50} alt="" /></td>
                  <td>{item?.createdBy?.name}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default BlogCategories