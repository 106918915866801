import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCountries, getCountryCity, sendClaimSpaceEmail } from '../../store/apiSlice';
import Spinner from '../../components/Spinner';
import toast from 'react-hot-toast';
import ContentSection from '../../components/elements/ContentSection';
import Breadcrumb from '../../components/elements/Breadcrumb';
import Title from '../../components/elements/Title';
import BoxSection from '../../components/elements/BoxSection';
import Button from '../../components/elements/Button';

const PushEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [countries, setcountries] = useState([]);
    const [cities, setcities] = useState([]);
    const [country, setcountry] = useState('');
    const [countryName, setcountryName] = useState('');
    const [cityName, setcityName] = useState('');
    const [countryFilter, setcountryFilter] = useState('');
    const [cityFilter, setcityFilter] = useState('');
    const [load, setload] = useState(false);

    const Countries = async () => {
        try {
            setcountries(null);
            const response = await dispatch(getAllCountries()).unwrap();
            setcountries(response?.data);

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const Cities = async (countryId) => {
        try {
            setcities(null);
            const response = await dispatch(getCountryCity(countryId)).unwrap();
            setcities(response?.data);

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        if (country) {
            Cities(country);
        }
    }, [country]);

    useEffect(() => {
        Countries();
    }, [])
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const [showDropdown1, setShowDropdown1] = useState(false);
    const [searchValue1, setSearchValue1] = useState('');
    const [highlightedIndex1, setHighlightedIndex1] = useState(-1);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value.length > 0) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    const handleOptionClick = (data) => {
        setSearchValue(data?.name);
        setcountry(data?.id);
        setcountryName(data?.name)
        setcountryFilter(data?._id)
        setShowDropdown(false);
    };

    const handleInputChange1 = (e) => {
        const value = e.target.value;
        setSearchValue1(value);
        if (value.length > 0) {
            setShowDropdown1(true);
        } else {
            setShowDropdown1(false);
        }
    };
    const handleOptionClick1 = (data) => {
        setSearchValue1(data?.name);
        setcityName(data?.name);
        setcityFilter(data?._id);
        setShowDropdown1(false);
    };

    const filteredCountries = countries?.filter((data) =>
        data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    const filteredCities = cities?.filter((data) =>
        data?.name?.toLowerCase().includes(searchValue1?.toLowerCase())
    );

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            // Move to the next item in the list
            setHighlightedIndex((prevIndex) =>
                prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            // Move to the previous item in the list
            setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (e.key === 'Enter') {
            // Select the highlighted item when Enter key is pressed
            if (highlightedIndex !== -1) {
                handleOptionClick(filteredCountries[highlightedIndex]);
            }
        }
    };
    const handleKeyDown1 = (e) => {
        if (e.key === 'ArrowDown') {
            // Move to the next item in the list
            setHighlightedIndex1((prevIndex) =>
                prevIndex < filteredCities.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            // Move to the previous item in the list
            setHighlightedIndex1((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (e.key === 'Enter') {
            // Select the highlighted item when Enter key is pressed
            if (highlightedIndex !== -1) {
                handleOptionClick1(filteredCities[highlightedIndex1]);
            }
        }
    };

    const SendPushEmail = async () => {
        try {
            if (!countryFilter) {
                toast.error("Select Country First");
                return;
            }
            setload(true);
            await dispatch(sendClaimSpaceEmail({ countryId: countryFilter, cityId: cityFilter })).unwrap();
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    return (
        <>
            {load && <Spinner />}
            <ContentSection title="Claim Push Email">
                <Breadcrumb items={[{ label: 'Menu', }, { label: 'Claim Push Email', active: true },]} />
                <Title bottomSpace={3}>Claim Push Email</Title>
                <BoxSection bottomSpace={4}>
                    <div className="row gy-3">
                        <div className="col-md-6">
                            <div class="form-floating cw-input">
                                <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue} id="country" placeholder="country" autoComplete="off" />
                                <label for="country">Select Country</label>
                            </div>
                            {showDropdown && (
                                <div className="dropdown cw-select-dropdown">
                                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                        {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                            <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                                        ))}
                                        {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                            <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                        {country && (
                            <div className="col-md-6">
                                <div class="form-floating cw-input">
                                    <input type="text" class="form-control" onKeyDown={handleKeyDown1} maxLength={120} onChange={(e) => handleInputChange1(e)} value={searchValue1} id="city" placeholder="city" autoComplete="off" />
                                    <label for="city">City</label>
                                </div>
                                {showDropdown1 && (
                                    <div className="dropdown cw-select-dropdown">
                                        <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                            {cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.map((data, index) => (
                                                <li key={index} onClick={() => handleOptionClick1(data)} className={highlightedIndex1 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                                            ))}
                                            {cities?.length > 0 && cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.length === 0 && (
                                                <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </BoxSection>

                <div class="d-flex justify-content-center gap-2">
                    <Button variant="black-outline" size="small" onClick={SendPushEmail}>Send Email</Button>
                </div>
            </ContentSection>
        </>
    )
}

export default PushEmail