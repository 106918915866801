import { useLocation, useNavigate } from "react-router-dom";
import { deleteCity, editCity, getAllCountries, getCountryCitySearch, getProfile } from "../../store/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import DeleteModal from "../../components/DeleteModal";
import { Pencil, Search, Trash2 } from "lucide-react";
import Pagination from "../../components/elements/Pagination";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";

const Cities = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile)
  const [search, setsearch] = useState('');
  const [search2, setsearch2] = useState('');
  const [totalCount, settotalCount] = useState('');
  const [cities, setcities] = useState(null);
  const [countries, setcountries] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [id, setId] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [cityCountry, setcityCountry] = useState("");
  const [imageName, setimageName] = useState("");
  const [feature, setfeature] = useState("");
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);


  const viewModal = async (item, type) => {
    setIsOpen(true);
    if (type === "edit") {
      setUserDetail(item);
      setcity(item?.name);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const cityDelete = async (id) => {
    try {
      await dispatch(deleteCity(id)).unwrap();
      setIsOpen(false);
      setcities((prevCities) => prevCities?.filter(city => city?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const Cities = async (countryId) => {
    try {
      setload(true)
      setcities(null);
      const response = await dispatch(getCountryCitySearch({ countryId, page: currentPage, search: search2 })).unwrap();
      setcities(response?.data);
      settotalCount(response?.count)
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  useEffect(() => {
    if (cityCountry) {
      Cities(cityCountry);
    }
  }, [cityCountry, search2, currentPage]);

  useEffect(() => {
    Countries();
  }, []);


  const addandEditCity = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", city);
      data.append("cityImage", imageName);
      await dispatch(editCity(data)).unwrap();
      setIsOpen(false);
      Cities(cityCountry);
      setId("")
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    setcountry(userDetail?.countryId?._id)
  }, [userDetail])

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    setcityCountry(data?.id);
    setShowDropdown(false);
  };

  const filteredCountries = countries?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);


  const handleKeyDown2 = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setsearch2(search);
    }
  };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "edit" ? (
          <ModalContent title="Edit City" closeModal={closeModal} onClick={addandEditCity} buttonText="Update">
            <FloatingInput value={city} onChange={(e) => setcity(e.target.value)} placeholder="City Name" bottomSpace={3} />
            <FloatingInput type="file" onChange={(e) => setimageName(e.target.files[0])} placeholder="City Banner" />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => cityDelete(id)} label="City" />
        ) : null}
      </CustomModal>

      <ContentSection title="Cities">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Cities', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Cities</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <div className="">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue} id="country" placeholder="country" autoComplete="off" />
                <label for="country">Select Country</label>
              </div>
              {showDropdown && (
                <div className="dropdown cw-select-dropdown">
                  <ul className="dropdown-menu show" style={{ width: '100%' }}>
                    {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                      <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                    ))}
                    {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                      <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2">
          </div>
        </div>
        <BoxSection>
          <div className="row mb-3">
            <div className="col-md-6 align-self-center">
              <p className="font-size-20 pink-color mb-0">Cities : {totalCount}</p>
            </div>
            <div className="col-md-6">
              <div class="flex-grow-1 sm-search-chats">
                <Search width="18" height="18" className="sm-search-chats-icon" stroke="var(--color3)" />
                <input type="text" class="form-control sm-search-chats-field rounded-0" style={{ borderColor: "var(--color3) !important" }} placeholder="Search..." value={search}
                  onChange={(e) => setsearch(e.target.value)} onKeyDown={handleKeyDown2} />
              </div>
            </div>
          </div>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">City Name</th>
                <th scope="col">Country</th>
                <th scope="col">Total Spaces</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : cities?.map((item, i) => (
                <tr key={i}>
                  <td>{i + startIndex + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.country_name}</td>
                  <td>{item?.noOfSpaces}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
          {totalCount > 20 &&
            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
          }
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Cities