import React from 'react'

const BoxSection = ({ children, className = "", topSpace = 0, bottomSpace = 0, padding = 3 }) => {
    const validTopSpace = Math.min(Math.max(topSpace, 0), 5);
    const validBottomSpace = Math.min(Math.max(bottomSpace, 0), 5);
    const validPadding = Math.min(Math.max(padding, 0), 5);
    return (
        <>
            <div className={`white-bg cw-shadow mt-${validTopSpace} mb-${validBottomSpace} p-${validPadding} ${className}`}>
                {children}
            </div>

        </>
    )
}

export default BoxSection