import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { ArrowRight, LockKeyhole, Mail } from 'lucide-react';
import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';
import { getProfile, signinAdmin } from "../../store/apiSlice";
import Button from "../../components/elements/Button";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const [load, setload] = useState(false);
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSecureEntry, setisSecureEntry] = useState(true)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        email,
        password,
      }
      setload(true);
      await dispatch(signinAdmin(payload)).unwrap();
      navigate('/dashboard');
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
    <>
      <section class="vh-100 authentication-bg">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card shadow-sm rounded-0 border-0">
                <div class="card-body p-5 text-center">
                  <img className="mx-auto d-block auth-logo mb-5" width="300" src={"../assets/images/logo.svg"} />
                  <form onSubmit={handleSubmit}>
                    <div class="input-group cw-input-group mb-4">
                      <span class="input-group-text"><Mail /></span>
                      <div class="form-floating">
                        <input type="email" class="form-control" id="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} />
                        <label for="email">Email</label>
                      </div>
                    </div>
                    <div class="input-group cw-input-group mb-4">
                      <span class="input-group-text"><LockKeyhole /></span>
                      <div class="form-floating">
                        <input type={isSecureEntry ? "password" : "text"} class="form-control" id="Password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        <label for="Password">Password</label>
                        <span onClick={() => { setisSecureEntry((prev) => !prev) }} className="cw-eye-auth">{isSecureEntry ? <Eye /> : <EyeOff />}</span>
                      </div>
                    </div>
                    <Button type="submit" variant="black" fullwidth loading={load}>Log in</Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login