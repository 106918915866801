import React from 'react'

const SelectButton = ({checked = false, onChange, id, label, type = "radio", name, disabled = false, value}) => {
  return (
    <>
    <input checked={checked} onChange={onChange} type={type} name={name} value={value} class="btn-check" id={id} disabled={disabled} />
    <label class="btn cw-select-btn" for={id}>{label}</label>
    
    </>
  )
}

export default SelectButton