import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const HtmlTextEditor = ({value, onChange, label}) => {
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean']
    ];
  return (
    <>
    
    <div class="mb-3 pb-5 cw-html-text-editor">
        <p class="font-size-16 fw-semibold black-color mb-2">{label}</p>
        <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={{
            toolbar: toolbarOptions,
        }}
        style={{ height: '300px', marginBottom: '45px' }}
        />
    </div>
    </>
  )
}

export default HtmlTextEditor