import React, { useState, useContext } from 'react'
import { context } from '../context/context';
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProfile } from "../store/apiSlice"
import { AlignJustify, ChevronDown, ChevronUp } from 'lucide-react';


const Sidebar = () => {
    const profile = useSelector(getProfile)
    const location = useLocation();

    const { toggleButton, SetToggleButton } = useContext(context);
    const [toggleMenus, setToggleMenus] = useState({});

    const handleToggle = (index) => {
        setToggleMenus(prevState => ({
            ...prevState,
            [index]: !prevState[index] // Toggle the state of the clicked menu item
        }));
    };

    const hasAccess = (accessKey) => {
        return profile && profile[accessKey] && profile[accessKey].length > 0;
    };

    const menuItems = [
        {
            path: "/",
            label: "Dashboard",
            icon: "../assets/images/dashboard-icon.svg",
            hasSubMenu: false
        },
        {
            path: "/areas",
            label: "Areas",
            icon: "../assets/images/city-icon.svg",
            hasSubMenu: false,
            access: "system_access"
        },
        {
            path: "/cities",
            label: "Cities",
            icon: "../assets/images/city-icon.svg",
            hasSubMenu: false,
            access: "system_access"
        },
        {
            path: "/countries",
            label: "Countries",
            icon: "../assets/images/country-icon.svg",
            hasSubMenu: false,
            access: "system_access"
        },
        {
            path: "/inclusions",
            label: "Inclusion",
            icon: "../assets/images/country-icon.svg",
            hasSubMenu: false,
            access: "system_access"
        },
        {
            path: "/features",
            label: "Features",
            icon: "../assets/images/features-icon.svg",
            hasSubMenu: true,
            subMenu: [
                {
                    path: "/features",
                    label: "All Features"
                },
                {
                    path: "/feature-categories",
                    label: "Feature Categories"
                }
            ],
            access: "system_access"
        },
        {
            path: "/desk-types",
            label: "Desk Types",
            icon: "../assets/images/types-icon.svg",
            hasSubMenu: true,
            subMenu: [
                {
                    path: "/desk-types",
                    label: "Desk Types"
                },
                {
                    path: "/room-types",
                    label: "Room Types"
                },
            ],
            access: "system_access"
        },
        {
            path: "/blogs",
            label: "Blogs",
            icon: "../assets/images/blogs-icon.svg",
            hasSubMenu: true,
            subMenu: [
                {
                    path: "/blogs",
                    label: "All Blogs"
                },
                {
                    path: "/blog-categories",
                    label: "Categories"
                },
            ],
            access: "blogs_access"
        },
        {
            path: "/admins",
            label: "Admin",
            icon: "../assets/images/admin-icon.svg",
            hasSubMenu: false,
            access: "role",
            role: "admin"
        },
        {
            path: "/admin-businesses",
            label: "Businesses",
            icon: "../assets/images/businesses-icon-cw.svg",
            hasSubMenu: true,
            subMenu: [
                {
                    path: "/admin-businesses",
                    label: "Businesses By Admin"
                },
                {
                    path: "/owner-businesses",
                    label: "Businesses By Owner"
                }
            ],
            access: "space_access"
        },
        {
            path: "/spaces",
            label: "Spaces",
            icon: "../assets/images/spaces-icon.svg",
            hasSubMenu: true,
            subMenu: [
                {
                    path: "/spaces",
                    label: "Spaces By Admin"
                },
                {
                    path: "/business-spaces",
                    label: "Spaces By Business"
                }
            ],
            access: "space_access"
        },
        {
            path: "/business-users",
            label: "Users",
            icon: "../assets/images/users-icon.svg",
            hasSubMenu: true,
            subMenu: [
                {
                    path: "/business-users",
                    label: "Business Users"
                },
                {
                    path: "/customer-users",
                    label: "Customer Users"
                }
            ],
            access: "users_access"
        },
        {
            path: "/user-requested-quotes",
            label: "Requested Quotes",
            icon: "../assets/images/requested-quotes-icon.svg",
            hasSubMenu: false,
            subMenu: [
                {
                    path: "/user-requested-quotes",
                    label: "User Requested Quotes"
                },
                {
                    path: "/guest-requested-quotes",
                    label: "Guest Requested Quotes"
                }
            ],
            access: "users_access"
        },
        {
            path: "/careers",
            label: "Careers",
            icon: "../assets/images/careers-icon-cw.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/claim-spaces",
            label: "Claim Spaces",
            icon: "../assets/images/claim-spaces-icon.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/report-spaces",
            label: "Report Spaces",
            icon: "../assets/images/report-spaces-icon.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/landlord-property",
            label: "Landlord Property",
            icon: "../assets/images/landlord-property-icon-cw.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/contact-us-list",
            label: "Contact Us",
            icon: "../assets/images/contact-us-icon-cw.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/enterprise-solutions",
            label: "Enterprise Solutions",
            icon: "../assets/images/enterprise-solution-icon-cw.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/newsletter-emails",
            label: "Newsletter",
            icon: "../assets/images/newsletter-icon-cw.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/meta-data",
            label: "Meta Data",
            icon: "../assets/images/newsletter-icon-cw.svg",
            hasSubMenu: false,
            access: "blogs_access"
        },
        {
            path: "/abandoned-spaces",
            label: "Abandoned Spaces",
            icon: "../assets/images/newsletter-icon-cw.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/claim-invitation",
            label: "Claim Invitation",
            icon: "../assets/images/newsletter-icon-cw.svg",
            hasSubMenu: false,
            access: "users_access"
        },
        {
            path: "/booked-tour-list",
            label: "Booked Tours",
            icon: "../assets/images/newsletter-icon-cw.svg",
            hasSubMenu: false,
            access: "users_access"
        },
    ];


    return (
        <>
            <div className={toggleButton ? "sidebar close" : "sidebar"}>
                <div class="logo-details">
                    <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color2)' strokeWidth={1} />
                </div>
                <ul class="nav-links">
                    {menuItems.map((item, index) => {
                        if (item.access && !hasAccess(item.access)) {
                            return null;
                        }

                        if (item.role && profile.role !== item.role) {
                            return null;
                        }

                        return (
                            <li key={index} className={`${location.pathname === item.path ? "active" : ""}  showMenu`}>
                                <div className="icon-link" onClick={() => item.hasSubMenu && handleToggle(index)}>
                                    <Link to={item.path}>
                                        <img src={item.icon} alt={item.label} />
                                        <span className="link_name">{item.label}</span>
                                    </Link>
                                    {item.hasSubMenu && (
                                        <span className="cursor-pointer">
                                            {toggleMenus[index] ? <ChevronUp /> : <ChevronDown />}
                                        </span>
                                    )}
                                </div>
                                {item.hasSubMenu && toggleMenus[index] && (
                                    <ul className="sub-menu">
                                        {item.subMenu.map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                <Link className={`${location.pathname === subItem.path ? "active" : ""}`} to={subItem.path}>
                                                    {subItem.label}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>

    )
}

export default Sidebar
