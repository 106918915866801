import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteAdmin, getAllAdmins } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import DeleteModal from "../../components/DeleteModal";
import Button from "../../components/elements/Button";
import { Pencil, Trash2 } from "lucide-react";
import Breadcrumb from "../../components/elements/Breadcrumb";
import ContentSection from "../../components/elements/ContentSection";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import Title from "../../components/elements/Title";


const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [admins, setadmins] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const adminDelete = async (id) => {
    try {
      await dispatch(deleteAdmin(id)).unwrap();
      setIsOpen(false);
      setadmins((prevAdmins) => prevAdmins?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Admins = async () => {
    try {
      setload(true);
      setadmins(null);
      const response = await dispatch(getAllAdmins()).unwrap();
      setadmins(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Admins();
  }, []);


  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => adminDelete(id)} label="Admin" />
        ) : null}
      </CustomModal>

      <ContentSection title="Admins">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Admins', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Admins</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">

          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button onClick={() => navigate("/add-admin")}>Add Admin</Button>
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Admins : {admins?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Space</th>
                <th scope="col">Blogs</th>
                <th scope="col">Users</th>
                <th scope="col">System</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : admins?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.email}</td>
                  <td>{item?.space_access?.map((c, i) => (<>{c.charAt(0).toUpperCase() + c.slice(1)}{i < item?.space_access?.length - 1 ? ", " : ""}</>))}</td>
                  <td>{item?.blogs_access?.map((c, i) => (<>{c.charAt(0).toUpperCase() + c.slice(1)}{i < item?.blogs_access?.length - 1 ? ", " : ""}</>))}</td>
                  <td>{item?.users_access?.map((c, i) => (<>{c.charAt(0).toUpperCase() + c.slice(1)}{i < item?.users_access?.length - 1 ? ", " : ""}</>))}</td>
                  <td>{item?.system_access?.map((c, i) => (<>{c.charAt(0).toUpperCase() + c.slice(1)}{i < item?.system_access?.length - 1 ? ", " : ""}</>))}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => navigate("/edit-admin", { state: { _id: item }, })} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Admin