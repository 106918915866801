import React from 'react'
import ContentSection from '../../components/elements/ContentSection'
import Breadcrumb from '../../components/elements/Breadcrumb'
import Title from '../../components/elements/Title'

const DashboardAdmin = () => {
  return (
    <>
      <ContentSection title="Dashboard">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Dashboard', active: true },]} />
        <Title bottomSpace={3}>Dashboard</Title>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh", width: "100%", position: "absolute", top: 0, left: 0 }}>
          <div className="fa-3x">
            <dotlottie-player src="https://lottie.host/c37852a2-6f0a-411c-8dd9-01aaa52c65a5/Tx1pv6TyVl.json" background="transparent" speed="1" style={{ "width": "500px", "height": "500px" }} loop autoplay></dotlottie-player>
            <h2 className="text-uppercase text-center">The <span className="fw-bold">Coworking</span> Spaces</h2>
          </div>
        </div>
      </ContentSection>
    </>
  )
}

export default DashboardAdmin