import { Eye, Trash2 } from "lucide-react";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import TextDetail from "../../components/elements/TextDetail";
import { getProfile, getReportSpaces } from "../../store/apiSlice";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../components/DeleteModal";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";

const ReportSpaces = () => {
  const profile = useSelector(getProfile)
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [reportSpaces, setreportSpaces] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    if (type === "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type === "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const reportSpaceDelete = async (id) => {
    try {
      // await dispatch(deleteReportSpace(id)).unwrap();
      setIsOpen(false);
      ReportSpace();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const ReportSpace = async () => {
    try {
      setload(true);
      setreportSpaces(null);
      const response = await dispatch(getReportSpaces()).unwrap();
      setreportSpaces(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    ReportSpace();
  }, []);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Report Space Detail" closeModal={closeModal}>
            <TextDetail label="Name" data={userDetail?.name} />
            <TextDetail label="Email" data={userDetail?.email} />
            <TextDetail label="Phone" data={userDetail?.phone} />
            <TextDetail label="Reason" data={userDetail?.reason} />
            <TextDetail label="Space Name" data={userDetail?.spaceId?.name} />
            <TextDetail label="Comment" data={userDetail?.comment} />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => reportSpaceDelete(id)} label="Report Space" />
        ) : null}
      </CustomModal>


      <ContentSection title="Report Spaces">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Report Spaces', active: true },]} />
        <Title bottomSpace={2}>Report Spaces</Title>

        <BoxSection>
          <p className="font-size-20 pink-color">Report Spaces : {reportSpaces?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Reason</th>
                <th scope="col">Space Name</th>
                <th style={{ width: '140px' }} scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : reportSpaces?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.phone}</td>
                  <td>{item?.reason}</td>
                  <td>{item?.spaceId?.name}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {profile?.users_access?.includes("read") && (<div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>)}
                      {profile?.users_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default ReportSpaces