import React from 'react'
import Button from './Button'

const ModalContent = ({title, closeModal, onClick, buttonText, children}) => {
  return (
    <>
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">{title}</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        {children}
        </div>
        {buttonText && <div class="modal-footer border-0 justify-content-center">
          <Button onClick={onClick} size="small">{buttonText}</Button>
        </div>}
    </div>
    </>
  )
}
export default ModalContent