import toast from 'react-hot-toast';
import axios from "axios";
import { addAdmin, addBlogCategory, addBlogs, addCity, addCityArea, addContractBusiness, addDeskRoomType, addFeature, addFeatureCategory, addInclusions, addMetaData, addReviewSpace, addSpaceType, alreadyUserBuisinessAssign, aproveBusiness, changeBusinessName, deleteAdmin, deleteBlogCategory, deleteBlogs, deleteCity, deleteCityArea, deleteDeskRoomType, deleteFeature, deleteFeatureCategory, deleteInclusions, deleteQuotation, deleteReviews, deleteSpace, editAdmin, editBlogCategory, editBlogs, editCity, editCityArea, EditCountry, editDeskRoomType, editFeature, editFeatureCategory, editInclusions, editMetaData, editReviewSpace, editSpaceTypee, FeaturedSpace, getAllAdmins, getAllCountries, getBlogCategory, getCity, getCountryCity, getCoworkingTypes, getDeskTypes, getFeatureCategories, getFeatures, getRoomDeskTypes, getRoomTypes, InviteBusinessOwner, offerQuotation, rejectQuotation, ResendInviteBusinessOwner, sendClaimSpaceEmail, sendEmailList, signinAdmin, signOut, spaceClosed, SpaceDetail, verifiedBusiness } from './apiSlice';

export const extraReducers = (builder) => {
    builder

        // SignIn
        .addCase(signinAdmin.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(signinAdmin.fulfilled, (state, action) => {
            state.status = 'succeeded'
            toast.success(action.payload.message)
            localStorage.setItem("user", JSON.stringify(action.payload.data));
            state.user = action.payload.data
            axios.defaults.headers.common['Authorization'] = action.payload.data.token;
            state.error = null
        })
        .addCase(signinAdmin.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        
        // signOut
        .addCase(signOut.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(signOut.fulfilled, (state, action) => {
            state.status = 'succeeded'
            localStorage.clear();
            state.status = 'succeeded'
            state.user = null
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(signOut.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // add admin
        .addCase(addAdmin.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addAdmin.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addAdmin.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // edit admin
        .addCase(editAdmin.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editAdmin.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editAdmin.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // delete admin
        .addCase(deleteAdmin.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteAdmin.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteAdmin.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })


        // add feature category
        .addCase(addFeatureCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addFeatureCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addFeatureCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // edit feature category
        .addCase(editFeatureCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editFeatureCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editFeatureCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // delete feature category
        .addCase(deleteFeatureCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteFeatureCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteFeatureCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // add feature
        .addCase(addFeature.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addFeature.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addFeature.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // edit feature
        .addCase(editFeature.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editFeature.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editFeature.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // delete feature
        .addCase(deleteFeature.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteFeature.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteFeature.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })


        // add desk room type
        .addCase(addDeskRoomType.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addDeskRoomType.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addDeskRoomType.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // edit desk room type
        .addCase(editDeskRoomType.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editDeskRoomType.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editDeskRoomType.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        
        // delete desk room type
        .addCase(deleteDeskRoomType.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteDeskRoomType.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteDeskRoomType.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // add city
        .addCase(addCity.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addCity.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addCity.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // edit city
        .addCase(editCity.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editCity.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editCity.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // delete city
        .addCase(deleteCity.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteCity.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteCity.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // add blog category
        .addCase(addBlogCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addBlogCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addBlogCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // edit blog category
        .addCase(editBlogCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editBlogCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editBlogCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // delete blog category
        .addCase(deleteBlogCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteBlogCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteBlogCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // add blog
        .addCase(addBlogs.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addBlogs.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addBlogs.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // edit blog
        .addCase(editBlogs.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editBlogs.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editBlogs.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // delete blog
        .addCase(deleteBlogs.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteBlogs.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteBlogs.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // add Inclusion
        .addCase(addInclusions.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addInclusions.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addInclusions.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // edit Inclusion
        .addCase(editInclusions.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editInclusions.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editInclusions.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // delete Inclusion
        .addCase(deleteInclusions.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteInclusions.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteInclusions.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // add Space Type
        .addCase(addSpaceType.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addSpaceType.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addSpaceType.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        
        // delete space
        .addCase(deleteSpace.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteSpace.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteSpace.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        
        // add review space
        .addCase(addReviewSpace.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addReviewSpace.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addReviewSpace.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // edit space type
        .addCase(editSpaceTypee.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editSpaceTypee.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editSpaceTypee.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Invite Business Owner
        .addCase(InviteBusinessOwner.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(InviteBusinessOwner.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(InviteBusinessOwner.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Resend Invite Business Owner
        .addCase(ResendInviteBusinessOwner.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(ResendInviteBusinessOwner.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(ResendInviteBusinessOwner.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // editCountry
        .addCase(EditCountry.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(EditCountry.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(EditCountry.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // aproveBusiness
        .addCase(aproveBusiness.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(aproveBusiness.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(aproveBusiness.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Featured Space
        .addCase(FeaturedSpace.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(FeaturedSpace.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(FeaturedSpace.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // edit Review
        .addCase(editReviewSpace.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editReviewSpace.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editReviewSpace.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Delete Reviews
        .addCase(deleteReviews.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteReviews.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteReviews.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Send Claim Space Email
        .addCase(sendClaimSpaceEmail.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(sendClaimSpaceEmail.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(sendClaimSpaceEmail.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // delete Quotation
        .addCase(deleteQuotation.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteQuotation.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteQuotation.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // delete Quotation
        .addCase(addContractBusiness.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addContractBusiness.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addContractBusiness.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })


        // verified Business
        .addCase(verifiedBusiness.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(verifiedBusiness.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(verifiedBusiness.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // space Closed
        .addCase(spaceClosed.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(spaceClosed.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(spaceClosed.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // addCityArea
        .addCase(addCityArea.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addCityArea.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addCityArea.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // editCityArea
        .addCase(editCityArea.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editCityArea.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editCityArea.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // deleteCityArea
        .addCase(deleteCityArea.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteCityArea.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteCityArea.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // add Meta Data
        .addCase(addMetaData.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addMetaData.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addMetaData.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // edit Meta Data
        .addCase(editMetaData.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(editMetaData.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(editMetaData.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // changeBusinessName
        .addCase(changeBusinessName.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(changeBusinessName.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(changeBusinessName.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // offerQuotation
        .addCase(offerQuotation.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(offerQuotation.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(offerQuotation.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // rejectQuotation
        .addCase(rejectQuotation.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(rejectQuotation.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(rejectQuotation.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // sendEmailList
        .addCase(sendEmailList.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(sendEmailList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(sendEmailList.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // alreadyUserBuisinessAssign
        .addCase(alreadyUserBuisinessAssign.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(alreadyUserBuisinessAssign.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(alreadyUserBuisinessAssign.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

}