import React from "react";

const UserProfile = ({ image, title, size = 50 }) => {
  const generateInitials = (name) => {
    if (!name) return "N/A"; // Default fallback
    const words = name.split(" ");
    return words
      .slice(0, 2) // Take at most 2 words
      .map((word) => word[0]?.toUpperCase()) // Take the first letter of each word
      .join("");
  };

  const imageUrl = image ? `${process.env.REACT_APP_IMGURL}w_200/q_auto/f_auto/${image}` : null;

  return image ? (
    <img src={imageUrl} alt={title} className="rounded-circle" style={{ objectFit: "cover" }} height={size} width={size}/>
  ) : (
    <div
      className="d-flex justify-content-center align-items-center rounded-circle"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: "var(--color3)", // Fallback background color
        color: "var(--color2)",
        border: "1px solid var(--color2)",
        fontSize: `${size / 2.5}px`,
        fontWeight: "bold",
        textTransform: "uppercase",
      }}
    >
      {generateInitials(title)}
    </div>
  );
};

export default UserProfile;