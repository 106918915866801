import { Search, X } from 'lucide-react'
import React, { useState } from 'react'
import Button from './Button';

const SearchDropdownFilter = ({applyFilter, resetFilter, children}) => {
    const [toggle, settoggle] = useState(false);
  return (
    <>
    <div class="dropdown cw-search-dropdown">
        <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
        <span>Filter and Search</span>
        <span>{toggle ? <X /> : <Search />}</span>
        </a>

        <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            {children}
        <div className="d-flex gap-2 justify-content-end">
            <Button size="small" onClick={applyFilter}>Search</Button>
            <Button size="small" variant="black-outline" onClick={resetFilter}>Reset</Button>
        </div>
        </ul>
    </div>
    
    </>
  )
}

export default SearchDropdownFilter