import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { addDeskRoomType, deleteDeskRoomType, editDeskRoomType, getDeskTypes, getInclusions, getPaymentCycle } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import FloatingTextArea from "../../components/elements/FloatingTextArea";
import HtmlTextEditor from "../../components/elements/HtmlTextEditor";
import SelectButton from "../../components/elements/SelectButton";
import DeleteModal from "../../components/DeleteModal";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import Button from "../../components/elements/Button";
import { Pencil, Trash2 } from "lucide-react";
import Title from "../../components/elements/Title";
import Breadcrumb from "../../components/elements/Breadcrumb";
import ContentSection from "../../components/elements/ContentSection";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";


const RoomDeskTypes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [id, setId] = useState("");
  const [deskTypes, setdeskTypes] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [name, setname] = useState("");
  const [behave, setbehave] = useState("");
  const [description, setdescription] = useState("");
  const [longDescription, setlongDescription] = useState("");
  const [keywords, setkeywords] = useState("");
  const [inclusion, setinclusion] = useState([]);
  const [paymentType, setpaymentType] = useState([]);
  const [inclusionsList, setinclusionsList] = useState([]);
  const [paymentCycleList, setpaymentCycleList] = useState([]);
  const [imageName, setimageName] = useState("");
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId();
      setname();
      setimageName();
      setinclusion([]);
      setpaymentType([]);
      setdescription();
      setlongDescription();
      setkeywords();
    } else if (type === "edit") {
      setUserDetail(item);
      setname(item?.name);
      setId(item?._id);
      setinclusion(item?.inclusion);
      setpaymentType(item?.paymentCycle);
      setdescription(item?.description);
      setlongDescription(item?.longDescription);
      setkeywords(item?.keywords);
      setbehave(item?.behave)
    }
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const typeDelete = async (id) => {
    try {
      await dispatch(deleteDeskRoomType(id)).unwrap();
      setIsOpen(false);
      setFilteredData((prev) => prev?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const Inclusions = async () => {
    try {
      setload(true);
      setinclusionsList(null);
      const response = await dispatch(getInclusions()).unwrap();
      setinclusionsList(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const PaymentCycles = async () => {
    try {
      setload(true);
      setpaymentCycleList(null);
      const response = await dispatch(getPaymentCycle()).unwrap();
      setpaymentCycleList(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Types = async () => {
    try {
      setload(true);
      setdeskTypes(null);
      setFilteredData(null);
      const response = await dispatch(getDeskTypes(behave)).unwrap();
      setdeskTypes(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    Inclusions();
    PaymentCycles();
  }, []);

  useEffect(() => {
    Types();
  }, [behave]);

  useEffect(() => {
    if (location.pathname === "/desk-types") {
      setbehave("Desk");
    } else if (location.pathname === "/room-types") {
      setbehave("Room");
    } else if (location.pathname === "/virtual-office-types") {
      setbehave("Virtual Office");
    }
  }, [location.pathname]);


  const addandEditdesktype = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("behave", behave);
      data.append("inclusion", JSON.stringify(inclusion));
      data.append("paymentCycle", JSON.stringify(paymentType));
      data.append("roomDeskImage", imageName);
      data.append("description", description);
      data.append("longDescription", longDescription);
      data.append("keywords", keywords);
      if (id) {
        await dispatch(editDeskRoomType(data)).unwrap();
      } else {
        await dispatch(addDeskRoomType(data)).unwrap();
      }
      setIsOpen(false);
      Types();
      setId("")
      setname("")
      setimageName("")
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const handleInclusionChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setinclusion(prevInclusions => [...prevInclusions, value]);
    } else {
      setinclusion(prevInclusions => prevInclusions.filter(item => item !== value));
    }
  }
  const handlePaymentCycleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setpaymentType(prevPaymentCycles => [...prevPaymentCycles, value]);
    } else {
      setpaymentType(prevPaymentCycles => prevPaymentCycles.filter(item => item !== value));
    }
  }


  useEffect(() => {
    setinclusion([]);
  }, []);

  const [nameFilter, setnameFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = deskTypes?.filter(item => {
      const category = item?.name.toLowerCase();
      return (
        (nameFilter === '' || category.includes(nameFilter.toLowerCase()))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };

  const resetFilter = () => {
    setFilteredData(deskTypes)
    setnameFilter('')
    settoggle(false);
  };

  const handleChange = (html) => {
    setlongDescription(html);
  };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal} size={modalType === "delete" ? "small" : 'full'}>
        {(modalType === "add" || modalType === "edit") ? (
          <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} ${behave} Type`} closeModal={closeModal} onClick={addandEditdesktype} buttonText={modalType === "add" ? "Submit" : "Update"}>
            <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Type Name" bottomSpace={3} />
            <FloatingInput type="file" onChange={(e) => setimageName(e.target.files[0])} placeholder="Type Icon" bottomSpace={3} />
            <FloatingTextArea value={description} onChange={(e) => setdescription(e.target.value)} placeholder="Description" bottomSpace={3} />
            <HtmlTextEditor value={longDescription} onChange={handleChange} label="Long Description" />
            <FloatingTextArea value={keywords} onChange={(e) => setkeywords(e.target.value)} placeholder="SEO Keywords" bottomSpace={3} />
            <div className="mb-3">
              <label className="d-block w-100 mb-2">Inclusions</label>
              <div className="d-flex flex-wrap gap-2">
                {inclusionsList?.map((data) => (
                  <SelectButton name="inclusions" type="checkbox" id={data?._id} checked={inclusion.includes(data?._id)} value={data?._id} onChange={(e) => handleInclusionChange(e)} label={data?.name} />
                ))}
              </div>
            </div>

            <div>
              <label className="d-block w-100 mb-2">Payment Cycles</label>
              <div className="d-flex flex-wrap gap-2">
                {paymentCycleList?.map((data) => (
                  <SelectButton name="payment-cycles" type="checkbox" id={data?._id} checked={paymentType.includes(data?._id)} value={data?._id} onChange={(e) => handlePaymentCycleChange(e)} label={data?.name} />
                ))}
              </div>
            </div>
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => typeDelete(id)} label={`${behave} Type`} />
        ) : null}
      </CustomModal>

      <ContentSection title={`${behave} Types`}>
        <Breadcrumb items={[{ label: 'Menu', }, { label: `${behave} Types`, active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>{behave} Types</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Type Name" />
                <label for="att-name">Type Name</label>
              </div>
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button onClick={() => viewModal(null, "add")}>Add {behave} Type</Button>
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">{behave} Types : {deskTypes?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Icon</th>
                <th scope="col">Created By</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td><img src={`${process.env.REACT_APP_IMGURL}/${item?.imageName}`} width={50} alt="" /></td>
                  <td>{item?.createdBy?.name}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default RoomDeskTypes