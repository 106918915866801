import React from 'react'
import Modal from "react-modal";


const customStyles = {
    content: {
      display:"block",
      paddingRight:"0"
    },
};
Modal.setAppElement("#root");

const CustomModal = ({modalIsOpen, closeModal, contentLabel = "Coworking Modal", size = "small", children}) => {
  return (
    <>
    <Modal closeTimeoutMS={0} isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel={contentLabel} className={`modal fade show cw-modal ${size === "small" ? "cw-modal-30" : ""}`}>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
            {children}
        </div>
    </Modal>
    </>
  )
}


export default CustomModal