import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FileAudio, FileText, FileVideo, Image, Paperclip, PlusCircle, Search, Send, SmilePlus, X, } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, sendTheAttachment } from '../../store/apiSlice';
import moment from 'moment';
import { io } from "socket.io-client";
import UserProfile from '../../components/elements/UserProfile';
import MessageText from '../../components/Chat/MessageText';
import QuoteMessage from '../../components/Chat/QuoteMessage';
import ImageMessage from '../../components/Chat/ImageMessage';
import VideoMessage from '../../components/Chat/VideoMessage';
import AudioMessage from '../../components/Chat/AudioMessage';
import DocumentMessage from '../../components/Chat/DocumentMessage';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
const socket = io(process.env.REACT_APP_SOCKET_URL);

const ChatInbox = ({ currentChat, getAllChats, startingToggle, setstartingToggle, messageChat }) => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)

    const [load, setload] = useState(true);
    const [loading, setloading] = useState(false);
    const [toggle1, settoggle1] = useState(false);
    const [messages, setmessages] = useState(null)
    const [attachments, setattachments] = useState(null)
    const [messageText, setmessageText] = useState("")
    const lastMessageRef = useRef(null);
    const [searchtext, setsearchtext] = useState("");
    const [filteredData, setfilteredData] = useState();
    const [photoFiles, setPhotoFiles] = useState([]);
    const [otherFile, setOtherFile] = useState(null);
    const [extension, setextension] = useState(null);

    const handleEmojiSelect = (emoji) => {
        setmessageText((prevText) => prevText + emoji.native);
    };

    const handleMessageChange = (e) => {
        setmessageText(e.target.value);
    };

    const sendAttachment = async () => {
        try {
            const form = new FormData();
            photoFiles.length > 0 ? photoFiles.forEach((item) => {
                form.append("chatAttachments", item);

            }) : [otherFile].forEach((item) => {
                form.append("chatAttachments", item);
            })
            const response = await dispatch(sendTheAttachment(form)).unwrap();
            setattachments(response?.data)
            console.log("get images attachment", response?.data);
            return response?.data
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const sendMessage = async () => {
        if (messageText) {
            if (startingToggle) {
                socket?.emit("get_messages", {
                    sender_id: profile?._id,
                    receiver_id: messageChat ? messageChat?.userId?._id : currentChat?.receiver_id ? (profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id) : currentChat._id,
                    group_id: messageChat?.group_id ? messageChat?.group_id : currentChat?.group_id ? currentChat?.group_id : null,
                });

                setstartingToggle(!startingToggle)
            } else {
                scrollToBottom();
                socket?.emit("send_message", {
                    sender_id: profile?._id,
                    receiver_id: messageChat ? messageChat?.userId?._id : currentChat?.sender_id ? profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id : currentChat._id,
                    message: messageText,
                    senderType: "admin",
                    receiverType: "user",
                    businessId: messageChat ? messageChat?.business?._id : currentChat?.businessId?._id,
                    group_id: messageChat?.group_id ? messageChat?.group_id : currentChat?.group_id ? currentChat?.group_id : null,
                });
                setmessageText("")
            }
        } else if (extension) {
            const data = await sendAttachment()
            scrollToBottom();
            socket?.emit("send_message", {
                sender_id: profile?._id,
                receiver_id: messageChat ? messageChat?.userId?._id : currentChat?.sender_id ? profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id : currentChat._id,
                senderType: "admin",
                receiverType: "user",
                businessId: messageChat ? messageChat?.business?._id : currentChat?.businessId?._id,
                group_id: messageChat?.group_id ? messageChat?.group_id : currentChat?.group_id ? currentChat?.group_id : null,
                message: attachments,
                type: extension,
                attachment: data,
            });
            setPhotoFiles([])
            setOtherFile(null)
            setextension(null)
            setattachments(null)
        }

    };

    const callMessage = () => {
        socket?.emit("get_messages", {
            sender_id: profile?._id,
            receiver_id: currentChat?.sender_id ? (profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id) : currentChat._id,
            group_id: currentChat?.group_id ? currentChat?.group_id : null,
        });
        socket?.on("response", (data) => {
            if (data?.object_type == "get_messages") {
                setmessages(data?.data);
                setfilteredData(data?.data);
                setload(false)
            } else if (data?.object_type == "get_message") {
                if (data?.data?.group_id === currentChat?.group_id || data?.data?.group_id === messageChat?.group_id) {
                    setmessages((prevMessages) => [...prevMessages, data?.data])
                    setfilteredData((prevMessages) => [...prevMessages, data?.data])
                    socket?.emit("unread_messages", {
                        sender_id: profile?._id,
                        receiver_id: profile?._id === currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id,
                    });
                    getAllChats();
                }
            }
        });
    };

    const callMessage2 = () => {
        socket?.emit("get_messages", {
            sender_id: profile?._id,
            receiver_id: messageChat?.userId ? messageChat?.userId?._id : null,
            group_id: messageChat?.group_id ? messageChat?.group_id : null,
        });
        socket?.on("response", (data) => {
            if (data?.object_type == "get_messages") {
                setmessages(data?.data);
                setfilteredData(data?.data);
                setload(false)
            } else if (data?.object_type == "get_message") {
                if (data?.data?.group_id === currentChat?.group_id || data?.data?.group_id === messageChat?.group_id) {
                    setmessages((prevMessages) => [...prevMessages, data?.data])
                    setfilteredData((prevMessages) => [...prevMessages, data?.data])
                    getAllChats();
                }
            }
        });
    };




    useEffect(() => {
        if (currentChat) {
            setload(true)
            callMessage();
            return () => {
                socket?.off('response');
            };
        }
    }, [socket, currentChat]);


    useEffect(() => {
        if (messageChat) {
            setload(true)
            callMessage2();
            return () => {
                socket?.off('response');
            };
        }
    }, [socket, messageChat]);



    useEffect(() => {
        if (currentChat) {
            lastMessageRef.current.scrollTop = lastMessageRef?.current?.scrollHeight;
        }
    }, [currentChat, messageChat]);

    const scrollToBottom = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollTop = lastMessageRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [currentChat, filteredData, messageChat]);



    const handleSearch = useCallback(() => {
        if (!searchtext.trim()) {
            setfilteredData(messages); // Reset to original messages if search is empty
            return;
        }

        const lowerCaseSearch = searchtext.toLowerCase();
        const filters = messages.filter((i) =>
            i?.message?.toLowerCase().includes(lowerCaseSearch)
        );

        setfilteredData(filters);
    }, [searchtext, messages]); // Depend on searchtext & messages

    useEffect(() => {
        handleSearch();
    }, [searchtext, handleSearch]);

    const handleSearchClose = () => {
        setsearchtext("");
        setfilteredData(messages);
        settoggle1(false);
    };



    const handleFileChange = (event, fileType) => {
        if (!event.target.files || event.target.files.length === 0) {
            console.error("No files selected.");
            return;
        }

        const maxSize = 5 * 1024 * 1024;

        // Get the first selected file
        const selectedFile = event.target.files[0];

        // Validate the image
        const isImage =
            selectedFile.type === "image/png" ||
            selectedFile.type === "image/jpeg" ||
            selectedFile.type === "image/jpg";
        const isValidSize = selectedFile.size <= maxSize;

        if (!isImage && fileType === "image") {
            alert(`${selectedFile.name} is not a valid image type (accepted: .png, .jpg, .jpeg).`);
            return;
        }

        if (!isValidSize) {
            alert(`${selectedFile.name} exceeds the 5MB size limit.`);
            return;
        }
        // if (selectedFile) {
        //     const type = getFileType(selectedFile);
        setextension(fileType);
        // }
        if (fileType === 'image') {
            setPhotoFiles((prevFiles) => [...prevFiles, ...event.target.files]);
        } else {
            setOtherFile(selectedFile);
        }
    };

    // Function to remove a specific photo file
    const handleRemovePhoto = (index) => {
        setPhotoFiles((prevFiles) => prevFiles?.filter((_, i) => i !== index));
    };

    // Function to remove the other file
    const handleRemoveOtherFile = () => {
        setOtherFile(null);
    };

    const fileIcons = {
        image: <Image width={22} height={22} stroke={"var(--color3)"} />,
        document: <FileText width={22} height={22} stroke={"var(--color3)"} />,
        audio: <FileAudio width={22} height={22} stroke={"var(--color3)"} />,
        video: <FileVideo width={22} height={22} stroke={"var(--color3)"} />,
    };

    const getFileType = (file) => {
        const extension = file.name.split('.').pop().toLowerCase();

        if (extension.match(/(jpg|jpeg|png|gif|bmp)$/)) {
            return 'image';
        } else if (extension.match(/(doc|docx|ppt|pptx|txt|pdf|xls|xlsx)$/)) {
            return 'document';
        } else if (extension.match(/(mp3)$/)) {
            return 'audio';
        } else if (extension.match(/(mp4|mov|avi|mkv)$/)) {
            return 'video';
        } else {
            return 'other';
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent the default action of adding a new line
            sendMessage(); // Send the message when Enter is pressed without Shift
        }
    };

    const groupedMessages = filteredData?.reduce((acc, message) => {
        const messageDate = moment(message?.createdAt).format("YYYY-MM-DD");
        if (!acc[messageDate]) acc[messageDate] = [];
        acc[messageDate].push(message);
        return acc;
    }, {});

    const isSender = profile?._id === currentChat?.sender_id[0]?._id;
    const userImage = messageChat ? "" : isSender ? currentChat?.receiver_id[0]?.imageName ? currentChat?.receiver_id[0]?.imageName : null : currentChat?.sender_id[0]?.imageName ? currentChat?.sender_id[0]?.imageName : null;
    const userName = messageChat ? messageChat?.userId?.name : isSender ? currentChat?.receiver_id[0]?.name : currentChat?.sender_id[0]?.name;
    const businessName = messageChat ? messageChat?.business?.name : currentChat?.businesses?.name;

    return (
        <>

            <div class="py-2 px-4 yellow-bg">
                <div style={{ marginBottom: '5px' }} class="d-flex align-items-center gap-3 flex-wrap">
                    <div class="flex-grow-1 d-flex gap-2 align-items-center ">
                        <UserProfile image={userImage} title={userName ? userName : businessName} />
                        <p className="font-size-16 mb-0 fw-semibold">{userName} (<b>{businessName}</b>)</p>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                        {toggle1 == true ?
                            <div class="sm-search-chats">
                                <Search width="18" height="18" className="sm-search-chats-icon" />
                                <input type="text" class="form-control sm-search-chats-field rounded-0" placeholder="Search..." value={searchtext}
                                    onChange={(e) => setsearchtext(e.target.value)} />
                            </div>
                            : null}
                        <div onClick={() => settoggle1(!toggle1)} className="light-blue-color cursor-pointer">
                            {toggle1 ? <X width="22" height="22" /> : <Search width="22" height="22" />}
                        </div>
                    </div>
                </div>
            </div>



            <div className={`sm-chat-messages p-4 ${load ? "d-flex justify-content-center" : ""} ${(photoFiles?.length > 0 || otherFile) ? "sm-chat-messages-picture-select" : ""}`} ref={lastMessageRef}>
                {load ? (
                    <div className="text-center my-3">
                        <div className="spinner-border yellow-color" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-2 fw-bold">Loading Chats...</p>
                    </div>
                ) : Object.entries(groupedMessages).map(([date, messages], groupIndex) => (
                    <React.Fragment key={groupIndex}>
                        {/* Date Label */}
                        <div className="text-center my-2">
                            <span class="badge font-size-12 grey-bg fw-normal black-color">{moment(date).isSame(moment(), "day") ? "Today" :
                                moment(date).isSame(moment().subtract(1, "day"), "day") ? "Yesterday" :
                                    moment(date).format("dddd, MMM D YYYY")}</span>
                        </div>

                        {messages?.map((data, index) => {
                            const isCurrentUser = profile?._id == data?.sender_id;
                            return (
                                <div className={isCurrentUser ? "sm-chat-message-right" : "sm-chat-message-left"} key={index}>
                                    <div className="flex-shrink-1">
                                        {data?.type == "message" ?
                                            <MessageText data={data} isCurrentUser={isCurrentUser} />
                                            : data?.type == "quote" ?
                                                <QuoteMessage data={data} isCurrentUser={isCurrentUser} />
                                                : data?.type == "image" ?
                                                    <ImageMessage data={data} isCurrentUser={isCurrentUser} />
                                                    : data?.type == "audio" ?
                                                        <AudioMessage data={data} isCurrentUser={isCurrentUser} />
                                                        : data?.type == "document" ?
                                                            <DocumentMessage data={data} isCurrentUser={isCurrentUser} />
                                                            : data?.type == "video" ?
                                                                <VideoMessage data={data} isCurrentUser={isCurrentUser} />
                                                                : null}
                                        <div className="d-flex gap-3 align-items-center sm-text-bottom">
                                            <p className="font-size-10 mb-0 black-color">{moment(data?.createdAt).format("LT")}</p>
                                            {/* <p className="font-size-10 mb-0 sm-seen-tick"><CheckCheck width="10" height="10" /> Seen</p> */}
                                        </div>
                                        {/* {data?.senderType === "admin" && <p className="mb-0 font-size-12 fw-medium">Reply from The CoWorking Spaces <br />by Admin ({data?.sender_detail[0]?.name})</p>} */}
                                    </div>
                                </div>
                            )
                        })}
                    </React.Fragment>
                ))}
            </div>

            <div class="py-2 px-3 sm-shadow  sm-write-message yellow-bg">
                {(photoFiles?.length > 0 || otherFile) && (<div className="sm-upload-box">
                    {photoFiles?.map((file, index) => (
                        <div key={index} className="sm-uploaded-image-size">
                            <img src={URL.createObjectURL(file)} alt={file.name} />
                            <div className="crox-icon" onClick={() => handleRemovePhoto(index)}><X width="18" height="18" /></div>
                        </div>
                    ))}
                    {otherFile && (
                        <div className="sm-selected-file">
                            {fileIcons[getFileType(otherFile)]}
                            <div>
                                <p className="font-size-14 black-color titilium-font mb-0">{otherFile.name}</p>
                                <p className="font-size-10 color-grey titilium-font mb-0">{otherFile.type}</p>
                            </div>
                            <div className="crox-icon" onClick={handleRemoveOtherFile}><X width="18" height="18" /></div>
                        </div>
                    )}
                </div>)}
                <div className="d-flex gap-3">
                    <div className="d-flex align-items-center gap-3">
                        <div className="dropdown sm-chat-dropdown">
                            <div className="light-blue-color cursor-pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false"><PlusCircle width="22" height="22" /></div>
                            <div className="dropdown-menu">
                                <div className="d-flex align-items-center gap-2 mb-3">
                                    <div className="color-grey"><Paperclip width="22" height="22" /></div>
                                    <p className="font-size-16 mb-0"><b>Attachment</b></p>
                                </div>
                                <div className="d-flex align-items-center gap-3 px-2">
                                    <label for="doc-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                                        <FileText width={22} height={22} stroke={"var(--color3)"} />
                                        <p className="font-size-12 fw-semibold mb-0">Doc</p>
                                    </label>
                                    <input onChange={(e) => handleFileChange(e, 'document')} max={1} type="file" className="btn-check" id="doc-attachment" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf" />

                                    <label for="gallery-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                                        <Image width={22} height={22} stroke={"var(--color3)"} />
                                        <p className="font-size-12 fw-semibold mb-0">Gallery</p>
                                    </label>
                                    <input multiple onChange={(e) => handleFileChange(e, 'image')} type="file" className="btn-check" id="gallery-attachment" accept="image/*" />

                                    <label for="audio-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                                        <FileAudio width={22} height={22} stroke={"var(--color3)"} />
                                        <p className="font-size-12 fw-semibold mb-0">Audio</p>
                                    </label>
                                    <input onChange={(e) => handleFileChange(e, 'audio')} max={1} type="file" className="btn-check" id="audio-attachment" accept=".mp3,audio/*" />

                                    <label for="video-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                                        <FileVideo width={22} height={22} stroke={"var(--color3)"} />
                                        <p className="font-size-12 fw-semibold mb-0">Video</p>
                                    </label>
                                    <input onChange={(e) => handleFileChange(e, 'video')} max={1} type="file" className="btn-check" id="video-attachment" accept="video/mp4,video/x-m4v,video/*" />
                                </div>
                            </div>
                        </div>
                        <div className="dropdown sm-chat-dropdown">
                            <div className="light-blue-color cursor-pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false"><SmilePlus width="22" height="22" /></div>
                            <div className="dropdown-menu">
                                <div className="d-flex align-items-center gap-2 mb-3">
                                    <div className="pink-color"><SmilePlus width="22" height="22" /></div>
                                    <p className="font-size-16 mb-0"><b>Emojis</b></p>
                                </div>
                                <div><div><Picker data={data} onEmojiSelect={handleEmojiSelect} /></div></div>
                            </div>
                        </div>
                    </div>
                    <textarea style={{ boxShadow: 'none', border: 0 }} value={messageText} onKeyDown={handleKeyDown} class="form-control rounded-0" rows={1} placeholder="Type your message here..." onChange={handleMessageChange}></textarea>
                    <div className="light-blue-color cursor-pointer align-self-center">
                    {loading ? <div class="spinner-border spinner-border-sm black-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div> : <Send width="22" height="22" onClick={sendMessage} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatInbox