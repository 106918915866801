import React from 'react'
import Button from './elements/Button'

const DeleteModal = ({closeModal, onClick, label}) => {
  return (
    <>
    <div class="modal-content">
        <div class="modal-header border-0 pb-0">
        <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete {label}</h1>
        <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-0">
            <p class="font-size-14 color-black mb-0">Are you sure you want to delete this {label.toLowerCase()}?</p>
        </div>
        <div class="modal-footer border-0 justify-content-center gap-2">
            <Button onClick={closeModal} variant='black-outline' size='small'>Cancel</Button>
            <Button onClick={onClick} size='small'>Delete</Button>
        </div>
    </div>
    
    </>
  )
}

export default DeleteModal