import React from 'react'

const MessageText = ({ data }) => {
    return (
        <>
            <div className="sm-message-text text-break" >
                {data?.message?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </div>
        </>
    )
}

export default MessageText