import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EditCountry, getAllCountries } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import FloatingTextArea from "../../components/elements/FloatingTextArea";
import HtmlTextEditor from "../../components/elements/HtmlTextEditor";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import { Pencil } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";


const Countries = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [countries, setcountries] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [name, setname] = useState("");
  const [imageName, setimageName] = useState("");
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);

  const [seoTitle, setseoTitle] = useState("");
  const [seoTitleDescription, setseoTitleDescription] = useState("");
  const [description, setdescription] = useState("");
  const [longDescription, setlongDescription] = useState("");
  const [keywords, setkeywords] = useState("");

  const viewModal = async (item, type) => {
    if (type === "edit") {
      setUserDetail(item);
      setname(item?.name);
      setId(item?._id);
      setseoTitle(item?.seoTitle)
      setseoTitleDescription(item?.seoTitleDescription)
      setdescription(item?.description)
      setlongDescription(item?.longDescription)
      setkeywords(item?.keywords)
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const Countries = async () => {
    try {
      setload(true);
      setcountries(null);
      setFilteredData(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Countries();
  }, []);


  const addandEditCountry = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("countryImage", imageName);
      data.append("seoTitle", seoTitle);
      data.append("seoTitleDescription", seoTitleDescription);
      data.append("description", description);
      data.append("longDescription", longDescription);
      data.append("keywords", keywords);
      await dispatch(EditCountry(data)).unwrap();
      setIsOpen(false);
      Countries();
      setId("")
      setseoTitle("")
      setseoTitleDescription("")
      setdescription("")
      setlongDescription("")
      setkeywords("")
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const [nameFilter, setnameFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = countries?.filter(item => {
      const category = item?.name.toLowerCase();

      return (
        (nameFilter === '' || category.includes(nameFilter.toLowerCase()))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };

  const resetFilter = () => {
    setFilteredData(countries)
    setnameFilter('')
    settoggle(false);
  };


  const handleChange = (html) => {
    setlongDescription(html);
  };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal} size="full">
        {modalType === "edit" ? (
          <>
            <ModalContent title="Edit Country" closeModal={closeModal} buttonText="Update" onClick={addandEditCountry}>
              <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Country Name" bottomSpace={3} disabled />
              <FloatingInput type="file" onChange={(e) => setimageName(e.target.files[0])} placeholder="Country Flag" bottomSpace={3} />
              <FloatingInput value={seoTitle} onChange={(e) => setseoTitle(e.target.value)} placeholder="SEO Title" bottomSpace={3} />
              <FloatingTextArea value={seoTitleDescription} onChange={(e) => setseoTitleDescription(e.target.value)} placeholder="SEO Description" bottomSpace={3} />
              <FloatingTextArea value={description} onChange={(e) => setdescription(e.target.value)} placeholder="Description" bottomSpace={3} />
              <HtmlTextEditor value={longDescription} onChange={handleChange} label="Long Description" />
              <FloatingTextArea value={keywords} onChange={(e) => setkeywords(e.target.value)} placeholder="SEO Keywords" />
            </ModalContent>
          </>
        ) : null}
      </CustomModal>

      <ContentSection title="Countries">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Countries', active: true },]} />

        <div className="row gy-3 mb-4 align-items-center">
          <div className="col-md-3 col-6 align-self-center order-1">
            <Title>Countries</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Country Name" autoComplete="off" />
                <label for="att-name">Country Name</label>
              </div>
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Countries : {countries?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Country Name</th>
                <th scope="col">Icon</th>
                <th scope="col">Currency</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(250).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.name}</td>
                  <td><img src={`${process.env.REACT_APP_IMGURL}/${item?.flag}`} width={30} alt="" /></td>
                  <td>{item?.currency}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Countries