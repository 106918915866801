import React from 'react'
import UserProfile from '../elements/UserProfile';
import { ImageDown } from 'lucide-react';

const ExistingChat = ({ data, profile, currentChat, handleSelectMessage }) => {
    const isSender = profile?._id === data?.sender_id[0]?._id;
    const userImage = isSender ? data?.receiver_id[0]?.imageName ? data?.receiver_id[0]?.imageName : null : data?.sender_id[0]?.imageName ? data?.sender_id[0]?.imageName : null;
    const userName = isSender ? data?.receiver_id[0]?.name : data?.sender_id[0]?.name;
    const businessName = data?.businesses?.name;
    const lastMessageTime = data?.last_message_date_time;

    return (
        <>
            <div className={`sm-message-user-list d-flex align-items-center gap-2 cursor-pointer ${data?._id === currentChat?._id ? 'active' : ''}`} onClick={() => handleSelectMessage(data)}>
                <UserProfile image={userImage} title={userName ? userName : businessName} />
                <div class="flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between mb-0">
                        <p className="font-size-14 light-blue-color titilium-font mb-0">{userName} (<b>{businessName}</b>)</p>
                        <p className="font-size-12 color-grey mb-0">{data?.last_message_date_time}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="font-size-14 titilium-font black-color mb-0">{data?.type === "message" ? (data?.message?.length > 20 ? `${data?.message?.substring(0, 20)}...` : data?.message) : data?.type === "quote" ? "Quotation" : <ImageDown />}</p>
                        {data?.noOfUnread == 0 ? <></> : <span className="sm-message-count yellow-bg black-color rounded-circle font-size-12">{data?.noOfUnread}</span>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExistingChat