import { useDispatch, useSelector } from "react-redux";
import { getProfile, getRequestedQuotes, getRoomDeskTypes, offerQuotation, rejectQuotation } from "../../store/apiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import TextDetail from "../../components/elements/TextDetail";
import DeleteModal from "../../components/DeleteModal";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import moment from "moment";
import { ArrowRight, Eye, Mail, MessageCircle, Trash2 } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";
import Button from "../../components/elements/Button";
import FloatingInput from "../../components/elements/FloatingInput";
import SelectButton from "../../components/elements/SelectButton";
import FloatingTextArea from "../../components/elements/FloatingTextArea";
import toast from "react-hot-toast";
import Pagination from "../../components/elements/Pagination";


const RequestQuoteSpaces = () => {
  const profile = useSelector(getProfile)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setId] = useState("");
  const [requestedQuotes, setrequestedQuotes] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);
  const [Types, setTypes] = useState(null);
  const [noOfPeopleResponse, setnoOfPeopleResponse] = useState("");
  const [offerComment, setofferComment] = useState("");
  const [comment, setcomment] = useState("");
  const [price, setPrice] = useState([]);
  const [listOfPaymentCycles, setlistOfPaymentCycles] = useState();

  const dataTypes = [
    { label: "New", value: "new" },
    { label: "Past", value: "past" },
  ]

  const [dataType, setdataType] = useState("new");
  const [search, setsearch] = useState('');
  const [totalCount, settotalCount] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } if (type == "offer") {
      if (item?.roomDeskType?._id && Types) {
        const matchedType = Types?.find((type) => type?._id === item?.roomDeskType?._id);
        if (matchedType) {
          const sortedPaymentCycles = [...(matchedType?.paymentcycles || [])]
            .sort((a, b) => {
              const sortingKeyA = typeof a?.SortingKey === 'number' && isFinite(a?.SortingKey) ? a?.SortingKey : Infinity;
              const sortingKeyB = typeof b?.SortingKey === 'number' && isFinite(b?.SortingKey) ? b?.SortingKey : Infinity;
              return sortingKeyA - sortingKeyB;
            });

          setlistOfPaymentCycles(sortedPaymentCycles);
          setPrice([{ type: sortedPaymentCycles?.[0]?.name, amount: "", }]);
        }
      }
      setUserDetail(item);
      setId(item?._id);
    } if (type == "reject") {
      setUserDetail(item);
      setId(item?._id);
    } if (type == "confirm") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
    setUserDetail("")
  }

  const requestedQuotesDelete = async (id) => {
    try {
      // await dispatch(({id: id})).unwrap();
      setIsOpen(false);
      RequestQuotes();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const RequestQuotes = async () => {
    try {
      setload(true);
      setrequestedQuotes(null);
      const response = await dispatch(getRequestedQuotes({ page: currentPage, limit: 20, dataType, search })).unwrap();
      settotalCount(response?.totalDocuments)
      setrequestedQuotes(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Type = async () => {
    try {
      setTypes(null);
      const response = await dispatch(getRoomDeskTypes()).unwrap();
      setTypes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    RequestQuotes();
  }, [currentPage, search, dataType]);

  useEffect(() => {
    Type();
  }, []);


  const [nameFilter, setnameFilter] = useState('');

  const applyFilter = () => {
    setsearch(nameFilter)
    setCurrentPage(1)
    settoggle(false);
  };

  const resetFilter = () => {
    setnameFilter('');
    setsearch('');
    settoggle(false);
  };



  const handleCheckboxChange = (id, name) => {
    setPrice((prev) => {
      // Check if the item is already selected
      const isSelected = prev.some((item) => item.type === name);

      if (isSelected) {
        // Remove the item if it is unchecked
        const updatedPrice = prev.filter((item) => item.type !== name);
        // Return updated price sorted based on the listOfPaymentCycles
        return updatedPrice.sort(
          (a, b) =>
            listOfPaymentCycles.findIndex((cycle) => cycle.name === a.type) -
            listOfPaymentCycles.findIndex((cycle) => cycle.name === b.type)
        );
      } else {
        // Add the item if it is checked
        const newItem = { type: name, amount: "" };
        const updatedPrice = [...prev, newItem];
        // Return updated price sorted based on the listOfPaymentCycles
        return updatedPrice.sort(
          (a, b) =>
            listOfPaymentCycles.findIndex((cycle) => cycle.name === a.type) -
            listOfPaymentCycles.findIndex((cycle) => cycle.name === b.type)
        );
      }
    });
  };

  const handlePriceChange = (type, amount) => {
    if (amount === "") {
      setPrice((prev) =>
        prev.map((item) =>
          item.type === type ? { ...item, amount: "" } : item
        )
      );
      return;
    }

    const numericAmount = Number(amount);
    if (numericAmount < 0) return; // Prevent setting negative values

    setPrice((prev) =>
      prev.map((item) =>
        item.type === type ? { ...item, amount: numericAmount } : item
      )
    );
  };


  const handleSendOffer = async () => {
    try {
      if (!noOfPeopleResponse) {
        toast.error('Please enter No of People');
        return;
      }
      if (price.some((item) => !item.amount || item.amount <= 0)) {
        toast.error('Please enter valid prices for all selected payment cycles.');
        return;
      }
      const payload = {
        quoteId: userDetail?._id,
        group_id: userDetail?.group_id,
        price: price,
        quoteResponse: {
          adminId: profile?._id,
          noOfPeopleResponse,
          comment: offerComment,
          date: new Date()
        }

      }
      const response = await dispatch(offerQuotation(payload)).unwrap();
      setrequestedQuotes((prevQuotes) =>
        prevQuotes.map((quote) =>
          quote._id === userDetail?._id ? { ...quote, price: payload.price, quoteResponse: payload.quoteResponse, isChatOwner: 1, group_id: response?.data?.group_id } : quote
        )
      );
      setnoOfPeopleResponse("")
      setofferComment("")
      setPrice([])
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const handleRejectOffer = async () => {
    try {
      if (!comment) {
        toast.error('Please enter a comment');
        return;
      }
      const payload = {
        quotationId: userDetail?._id,
        declineResponse: {
          adminId: profile?._id,
          message: comment,
          date: new Date()
        }
      }
      const response = await dispatch(rejectQuotation(payload)).unwrap();
      setrequestedQuotes((prevQuotes) =>
        prevQuotes.map((quote) =>
          quote._id === userDetail?._id ? { ...quote, status: "declined", declineResponse: payload.declineResponse } : quote
        )
      );
      setcomment("")
      closeModal();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const handleConfirmLead = async (id) => {
    try {
      setrequestedQuotes((prevQuotes) =>
        prevQuotes.map((quote) =>
          quote._id === id ? { ...quote, status: "closed" } : quote
        )
      );
      // setload1(true);
      // const payload = {
      //   businessId: business?._id,
      //   quotationId: id
      // }
      // const response = await dispatch(ConfirmLead(payload)).unwrap();
      setload1(false);
    } catch (rejectedValueOrSerializedError) {
      setload1(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleNoOfPeopleChange = (e) => {
    const value = Number(e.target.value);
    if (value < 0) return; // Prevent negative values
    setnoOfPeopleResponse(value); // Update state only with non-negative values
  };

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  console.log(totalCount, "Totalcount");
  console.log(totalPages, "totalPages");
  

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Requested Quotes Detail" closeModal={closeModal}>
            <TextDetail label="Name" data={userDetail?.userId?.name} />
            <TextDetail label="Email" data={userDetail?.userId?.email} />
            <TextDetail label="Phone" data={userDetail?.userId?.phone} />
            <TextDetail label="Occupation" data={userDetail?.userId?.occupation} />
            <TextDetail label="Company Name" data={userDetail?.userId?.companyName} />
            <TextDetail label="Duration" data={userDetail?.duration} />
            <TextDetail label="No Of People" data={userDetail?.noOfPeople} />
            <TextDetail label="Solution Type" data={userDetail?.roomDeskType?.name} />
            <TextDetail label="Space Name" data={userDetail?.spaceId?.name} />
            <TextDetail label="Space Email" data={userDetail?.spaceId?.spaceEmail} />
            <TextDetail label="Location" data={`${userDetail?.cities?.name}, ${userDetail?.countries?.name}`} />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => requestedQuotesDelete(id)} label="Requested Quote" />
        ) : modalType == "offer" ? (
          <ModalContent title="" closeModal={closeModal} onClick={handleSendOffer} buttonText="Submit Offer">
            <div className='mb-3 d-flex justify-content-between'><TextDetail label="Name" data={userDetail?.userId?.name} /></div>
            <div className='mb-3 d-flex justify-content-between'><TextDetail label="Email" data={userDetail?.userId?.email} /></div>
            <div className='mb-3 d-flex justify-content-between'><TextDetail label="Phone" data={userDetail?.userId?.phone} /></div>
            <div className='mb-3 d-flex justify-content-between'><TextDetail label="Occupation" data={userDetail?.userId?.occupation} /></div>
            <div className='mb-3 d-flex justify-content-between'><TextDetail label="Company Name" data={userDetail?.userId?.companyName} /></div>
            <div className='mb-3 d-flex justify-content-between'><TextDetail label="Solution Type" data={userDetail?.roomDeskType?.name} /></div>
            <div className='mb-3 d-flex justify-content-between'><TextDetail label="Duration" data={userDetail?.duration} /></div>
            <div className='mb-3 d-flex justify-content-between'><TextDetail label="No. of People" data={userDetail?.noOfPeople} /></div>
            <FloatingInput type='number' value={noOfPeopleResponse} onChange={handleNoOfPeopleChange} placeholder={`No. of People`} bottomSpace={3} />
            <p className="font-size-14 fw-semibold">Price By Payment Cycle</p>
            <div className="d-flex flex-wrap gap-2 mb-3">
              {listOfPaymentCycles?.map((item, i) => (
                <SelectButton key={i} type='checkbox' name="paymentCycles" id={item?._id} label={item?.name} onChange={() => handleCheckboxChange(item?._id, item?.name)} checked={price.some((selected) => selected.type === item?.name)} />
              ))}
            </div>
            <div className="mb-3">
              {price.map((item) => (
                <FloatingInput key={item.type} type="number" value={item.amount || ""} onChange={(e) => handlePriceChange(item.type, e.target.value)} placeholder={`${item?.type} Price`} bottomSpace={3} />
              ))}
            </div>
            <FloatingTextArea value={offerComment} onChange={(e) => setofferComment(e.target.value)} placeholder="Any Comments" bottomSpace={3} maxLength={200} />
            <p className="font-size-14 mb-0">This Message Will Send To User.</p>
          </ModalContent>
        ) : modalType == "reject" ? (
          <ModalContent title="" closeModal={closeModal} onClick={handleRejectOffer} buttonText="Submit">
            <FloatingTextArea value={comment} onChange={(e) => setcomment(e.target.value)} placeholder="Why you are rejecting?" bottomSpace={3} maxLength={200} />
            <p className="font-size-14 mb-0">This Message Will Send To User.</p>
          </ModalContent>
        ) : null}
      </CustomModal>

      <ContentSection title="Requested Quotes">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Requested Quotes', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Requested Quotes</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="name" placeholder="Name" />
                <label for="name">Name</label>
              </div>
              {/* <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setspaceNameFilter(e.target.value)} value={spaceNameFilter} class="form-control" id="space-name" placeholder="Space Name" />
                <label for="space-name">Space Name</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="date" class="form-control" id="date-filter" placeholder="Date" onChange={(e) => setdateFilter(e.target.value)} value={dateFilter} />
                <label for="date-filter">Date</label>
              </div>
              <div class="mb-3 sp-input-filter">
                <p class="font-size-20 fw-semibold mb-2">Business Owner / Not Accepted / Accepted</p>
                <div class="d-flex gap-2 flex-wrap">
                  <input type="checkbox" class="btn-check" id="business-owner" checked={BusinessOwnerFilter} onChange={() => setBusinessOwnerFilter(!BusinessOwnerFilter)} />
                  <label class="btn cw-select-btn" for="business-owner">Business Owner</label>

                  <input type="checkbox" class="btn-check" id="not-accepted" checked={notAcceptedFilter} onChange={() => setnotAcceptedFilter(!notAcceptedFilter)} />
                  <label class="btn cw-select-btn" for="not-accepted">Not Accepted</label>

                  <input type="checkbox" class="btn-check" id="accepted" checked={acceptedFilter} onChange={() => setacceptedFilter(!acceptedFilter)} />
                  <label class="btn cw-select-btn" for="accepted">Accepted</label>
                </div>
              </div> */}
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">

          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Requested Quotes: {requestedQuotes?.length}</p>
          <div class="d-flex gap-2 flex-wrap mb-3">
            {dataTypes?.map((data, i) => (
              <SelectButton key={i} name="dataTypes" value={data?.value} label={data?.label} checked={dataType === data?.value} id={data?.value} onChange={(e) => setdataType(e.target.value)} />
            ))}
          </div>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Space Name</th>
                <th scope="col">Business Name</th>
                <th scope="col">Location</th>
                {dataType === "new" && <th scope="col">Accepted By</th>}
                <th scope="col">Date</th>
                {dataType === "new" && <th scope="col">Offer/Confirm</th>}
                {dataType === "new" && <th scope="col">Reject</th>}
                <th style={{ width: '140px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={12}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : requestedQuotes?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.userId?.name ? item?.userId?.name : item?.guestUserId?.name}</td>
                  <td>{item?.spaceId?.name}</td>
                  <td>{item?.business?.name}</td>
                  <td>{item?.cities?.name}, {item?.countries?.name}</td>
                  {dataType === "new" && <td>{item?.business?.isAdmin === 0 ? "Business Owner" : item?.chatObject?.user ? item?.chatObject?.user?.name : item?.delinedUser?.name ? item?.delinedUser?.name : "Not Accepted"}</td>}
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  {dataType === "new" && <td style={{ 'verticalAlign': 'middle' }}>{item?.status === "accepted" ? <Button disabled size="small">Confirm Lead</Button> :
                    <Button disabled={item?.business?.isAdmin === 0 || item?.quoteResponse?.date || item?.declineResponse?.date} size="small" onClick={() => viewModal(item, "offer")}>Send Offer</Button>}</td>}
                  {dataType === "new" && <td className="text-capitalize" style={{ 'verticalAlign': 'middle' }}>{(item?.status === "declined" || item?.status === "accepted") ? item?.status : <Button disabled={item?.business?.isAdmin === 0 || item?.quoteResponse?.date || item?.declineResponse?.date} size="small" variant="black" onClick={() => viewModal(item, "reject")}>Reject Offer</Button>}</td>}
                  <td>
                    <div className="d-flex align-items-center gap-2">
                    {dataType === "new" && <>{item?.isChatOwner == 1 ?
                        <div onClick={() => navigate('/chat', { state: item })} className={`cw-action-icons`}><MessageCircle width="18" /></div>
                        : <div className={`cw-action-icons grey-bg`}><MessageCircle width="18" /></div>}</>}
                      {profile?.users_access?.includes("read") && (<div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>)}
                      {profile?.users_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
          {totalCount > 20 &&
            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />}
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default RequestQuoteSpaces