import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteReviews, editReviewSpace, getSpaceReviews, spaceClosed, SpaceDetail } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import FloatingInput from "../../components/elements/FloatingInput";
import FloatingTextArea from "../../components/elements/FloatingTextArea";
import DeleteModal from "../../components/DeleteModal";
import Button from "../../components/elements/Button";
import { ArrowRight, Clock, Eye, MapPinned, Pencil, Star, Trash2 } from "lucide-react";
import TextDetail from "../../components/elements/TextDetail";
import moment from "moment";
import Spinner from "../../components/Spinner";

const LocationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [load, setload] = useState(false);
  const [data, setdata] = useState(null);
  const [reviews, setreviews] = useState(null);
  const urlParts = window.location.pathname.split('/');
  const spaceId = urlParts[urlParts.length - 1];

  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [closingDate, setclosingDate] = useState("");
  const [status, setstatus] = useState("");

  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [date, setdate] = useState("");
  const [review, setreview] = useState("");

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "view-review") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type === "edit-review") {
      setUserDetail(item);
      setId(item?._id);
      setname(item?.name);
      setdescription(item?.description);
      setdate(item?.date);
      setreview(item?.rating);
    } else if (type == "delete") {
      setId(item);
    } else if (type == "close-space") {
      setstatus(item)
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("");
    setname("");
    setdescription("");
    setdate("");
    setreview("");
    setclosingDate("")
    setstatus("")
  }

  const SpaceDetails = async () => {
    try {
      setload(true)
      setdata(null);
      const response = await dispatch(SpaceDetail(spaceId)).unwrap();
      setdata(response?.data[0]);
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  const SpaceReviews = async () => {
    try {
      setload(true)
      setreviews(null);
      const response = await dispatch(getSpaceReviews(spaceId)).unwrap();
      setreviews(response?.data);
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
    SpaceDetails();
    SpaceReviews();
  }, []);

  useEffect(() => {
    if (data) {
      document.title = data?.name
    }
  }, [data, location.pathname]);


  const formatDays = (days) => {
    if (!days || days.length === 0) return '';
    const allDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const selectedDays = days.map(day => allDays.indexOf(day));
    selectedDays.sort((a, b) => a - b);
    if (selectedDays.length === 5 && selectedDays[0] === 0 && selectedDays[selectedDays.length - 1] === 4) {
      return 'Mon - Fri';
    }
    if (selectedDays.length === 7 && selectedDays[0] === 0 && selectedDays[selectedDays.length - 1] === 6) {
      return 'Mon - Sun';
    }
    const formatted = selectedDays.map(day => allDays[day]);
    return formatted.join(', ');
  };

  const formattedDays = formatDays(data?.days);

  const reviewDelete = async (id) => {
    try {
      await dispatch(deleteReviews(id)).unwrap();
      setIsOpen(false);
      SpaceReviews();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const editReview = async (id) => {
    try {
      const payload = {
        id: id,
        name: name,
        description: description,
        date: date,
        rating: review,
      }
      await dispatch(editReviewSpace(payload)).unwrap();
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const closeSpace = async () => {
    try {
      const payload = {
        id: spaceId,
        date: closingDate,
        status: status
      }
      await dispatch(spaceClosed(payload)).unwrap();
      setclosingDate("");
      closeModal();
      SpaceDetails();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "edit-review" ? (
          <ModalContent title={`Edit Review`} closeModal={closeModal} onClick={() => editReview(id)} buttonText="Update">
            <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" bottomSpace={3} />
            <FloatingInput type="date" value={date} onChange={(e) => setdate(e.target.value)} placeholder="Name" bottomSpace={3} />
            <FloatingTextArea value={description} onChange={(e) => setdescription(e.target.value)} placeholder="Description" bottomSpace={3} />
            <p className="font-size-14 fw-semibold mb-1">Review</p>
            <div className="star-rating">
              {[1, 2, 3, 4, 5].map((index) => (
                <span key={index} onClick={() => setreview(index)} className={index <= review ? 'star-filled' : 'star-empty'}>
                  {index <= review ? <Star size={24} stroke="var(--color3)" fill="var(--color3)" /> : <Star size={24} stroke="var(--color3)" />}
                </span>
              ))}
            </div>
          </ModalContent>
        ) : modalType == "view-review" ? (
          <ModalContent title="Review Detail" closeModal={closeModal}>
            <TextDetail label="Name" data={userDetail?.name} />
            <TextDetail label="Description" data={userDetail?.description} />
            <TextDetail label="Date" data={moment(userDetail?.date).format("DD-MM-YYYY")} />
            <TextDetail label="Review Count" data={userDetail?.rating} />
          </ModalContent>
        ) : modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => reviewDelete(id)} label="Review" />
        ) : modalType == "close-space" ? (
          <ModalContent title={`${status} This Space`} closeModal={closeModal} onClick={() => closeSpace()} buttonText="Submit">
            <FloatingInput type='date' value={closingDate} onChange={(e) => setclosingDate(e.target.value)} placeholder="Closing Date" />
          </ModalContent>
        ) : null}
      </CustomModal>

      {load && <Spinner />}
      <div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Spaces</a></li>
            <li class="breadcrumb-item active" aria-current="page">Space Details</li>
          </ol>
        </nav>
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-6">
            <h2 className="font-size-28 fw-bold mb-0">{data?.name}</h2>
          </div>
          <div className="col-md-6 d-flex justify-content-end gap-3">
            <Button onClick={() => navigate("/add-space-type", { state: data, })}>Add Space Type</Button>
            <Link class="btn cw-btn cw-yellow-btn px-3" to={`/edit-space/${spaceId}`}>Edit Space</Link>
          </div>
          <div className="col-md-6 d-flex gap-3">
            {data?.spaceStatus?.status !== "Temporary Closed" && <Button onClick={() => viewModal("Temporary Closed", "close-space")}>Temporary Close Space</Button>}
            {data?.spaceStatus?.status !== "Permanent Closed" && <Button onClick={() => viewModal("Permanent Closed", "close-space")}>Permanent Close Space</Button>}
          </div>
        </div>
        <div className="row justify-content-between mb-3">
          <div className="col-md-5 d-flex gap-2 align-items-center">
            <div><MapPinned size={40} stroke='var(--color3)' /></div>
            <div>
              <p className="fw-semibold font-size-16 mb-0">Address:</p>
              <p className="font-size-16 mb-0">{data?.location?.address}</p>
            </div>
          </div>
          <div className="col-md-3 d-flex gap-2 align-items-center">
            <div><MapPinned size={40} stroke='var(--color3)' /></div>
            <div>
              <p className="fw-semibold font-size-16 mb-0">Phone:</p>
              <p className="font-size-16 mb-0">{data?.spacePhone}</p>
            </div>
          </div>
          <div className="col-md-2 d-flex gap-2 align-items-center">
            <div><MapPinned size={40} stroke='var(--color3)' /></div>
            <div>
              <p className="fw-semibold font-size-16 mb-0">Email:</p>
              <p className="font-size-16 mb-0">{data?.spaceEmail}</p>
            </div>
          </div>
          <div className="col-md-2 d-flex gap-2 align-items-center">
            <div><MapPinned size={40} stroke='var(--color3)' /></div>
            <div>
              <p className="fw-semibold font-size-16 mb-0">Whatsapp:</p>
              <p className="font-size-16 mb-0">{data?.spaceMobile}</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-4">
          <div className="d-flex gap-2 align-items-center">
            <Clock stroke='var(--color3)' />
            <p className="fw-medium font-size-16 mb-0">{data?.spaceStartTime === "00:00" && data?.spaceEndTime === "23:59" ? "24 Hours" : 
            `${data?.spaceStartTime} to ${data?.spaceEndTime}`} ({formattedDays})
              {/* ({data?.days?.length > 0 && `${data.days[0]} - ${data.days[data.days.length - 1]}`}) */}
            </p>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <Star stroke='var(--color3)' />
            <p className="fw-medium font-size-16 mb-0">4.8 . 15 Reviews</p>
          </div>
        </div>

        <div className="mb-3">
          <p className="fw-semibold font-size-16 mb-0">Description:</p>
          <p className="font-size-16 mb-0">{data?.description}</p>
        </div>
        <div className="mb-3">
          <p className="fw-semibold font-size-16 mb-2">Features ({data?.features?.length}):</p>
          <div className="row gy-3 mb-3">
            {data?.features?.map((f, i) => (
              <div key={i} className="col-md-3 d-flex align-items-center gap-2">
                <img src={`${process.env.REACT_APP_IMGURL}/${f?.imageName}`} width={30} alt={f?.name} />
                <p className="font-size-16 grey-color mb-0">{f?.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-3">
          <p className="fw-semibold font-size-16 mb-2">Locations ({data?.relatedSpaces?.length}):</p>
          <div className="row gy-3">
            {data?.relatedSpaces?.map((item, i) => (
              <div className="col-md-3">
                <div className='p-3 yellow-bg'>
                  <div className="d-flex justify-content-between mb-1">
                    <p className="mb-0">{item?.name}</p>
                    <Link className="black-color" to={`/space-details/${item?._id}`}><ArrowRight /></Link>
                  </div>
                  <p>{item?.location?.address}</p>
                  <div className="d-flex gap-3">
                    <div className="cw-count">
                      <p className="mb-0">{item?.deskcategories_count}</p>
                      <p className="font-size-12 mb-0">Desk</p>
                    </div>
                    <div className="cw-count">
                      <p className="mb-0">{item?.rooms_count}</p>
                      <p className="font-size-12 mb-0">Room</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="cw-radius-20 white-bg cw-shadow p-3 mb-3">
          <p className="font-size-20 pink-color">Total Desk Spaces: {data?.desks?.length}</p>
          <div class="table-responsive">
            <table class="table cw-table table-borderless table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">type</th>
                  <th scope="col">No. of desks</th>
                  <th scope="col">Desk Area</th>
                  <th scope="col">Created By</th>
                  <th style={{ width: '100px' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.desks?.map((item, i) => (
                  <tr key={i}>
                    <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.type[0]?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.deskCount}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.deskSize}</td>
                    <td className="text-capitalize" style={{ verticalAlign: "middle" }}>{item?.adminsId?.role === "admin" ? item?.adminsId?.role : item?.adminsId?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center gap-2">
                        <Link to={`/edit-space-type/${item?._id}?behave=${item?.type[0]?.behave}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                        <div className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="cw-radius-20 white-bg cw-shadow p-3 mb-3">
          <p className="font-size-20 pink-color">Total Virtual Office: {data?.virtualoffices?.length}</p>
          <div class="table-responsive">
            <table class="table cw-table table-borderless table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">type</th>
                  <th scope="col">Created By</th>
                  <th style={{ width: '100px' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.virtualoffices?.map((item, i) => (
                  <tr key={i}>
                    <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.type[0]?.name}</td>
                    <td className="text-capitalize" style={{ verticalAlign: "middle" }}>{item?.adminsId?.role === "admin" ? item?.adminsId?.role : item?.adminsId?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center gap-2">
                        <Link to={`/edit-space-type/${item?._id}?behave=${item?.type[0]?.behave}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                        <div className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="cw-radius-20 white-bg cw-shadow p-3">
          <p className="font-size-20 pink-color">Total Rooms: {data?.rooms?.length}</p>
          <div class="table-responsive">
            <table class="table cw-table table-borderless table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">type</th>
                  <th scope="col">No. of Persons</th>
                  <th scope="col">Space Size</th>
                  <th scope="col">Created By</th>
                  <th style={{ width: '100px' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.rooms?.map((item, i) => (
                  <tr key={i}>
                    <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.type[0]?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.capacity}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.roomSize}</td>
                    <td className="text-capitalize" style={{ verticalAlign: "middle" }}>{item?.adminsId?.role === "admin" ? item?.adminsId?.role : item?.adminsId?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center gap-2">
                        <Link to={`/edit-space-type/${item?._id}?behave=${item?.type[0]?.behave}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                        <div className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="cw-radius-20 white-bg cw-shadow p-3">
          <p className="font-size-20 pink-color">Reviews: {reviews?.length}</p>
          <div class="table-responsive">
            <table class="table cw-table table-borderless table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Rating</th>
                  <th scope="col">Date</th>
                  <th style={{ width: '100px' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {reviews?.map((item, i) => (
                  <tr key={i}>
                    <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.name}</td>
                    <td>{item?.rating}</td>
                    <td>{moment(item?.date).format("DD-MM-YYYY")}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className={`cw-action-icons l-blue-bg`} onClick={() => viewModal(item, "view-review")}><Eye width="18" /></div>
                        <div className={`cw-action-icons l-blue-bg`} onClick={() => viewModal(item, "edit-review")}><Pencil width="18" /></div>
                        <div className={`cw-action-icons l-blue-bg`} onClick={() => viewModal(item?._id, "delete")}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default LocationDetails