import { useDispatch, useSelector } from "react-redux";
import { getLandlordProperty, getProfile } from "../../store/apiSlice";
import { useEffect, useState } from "react";
import CustomModal from "../../components/elements/Modal";
import ModalContent from "../../components/elements/ModalContent";
import TextDetail from "../../components/elements/TextDetail";
import moment from "moment";
import { Eye } from "lucide-react";
import ContentSection from "../../components/elements/ContentSection";
import Breadcrumb from "../../components/elements/Breadcrumb";
import Title from "../../components/elements/Title";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";

const LandlordProperty = () => {
  const profile = useSelector(getProfile)
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [properties, setProperties] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);

  function viewModal(item, type) {
    setIsOpen(true);
    if (type === "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type === "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }


  const LandLordProperties = async () => {
    try {
      setload(true);
      setProperties(null);
      const response = await dispatch(getLandlordProperty()).unwrap();
      setProperties(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    LandLordProperties();
  }, []);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "userDetail" ? (
          <ModalContent title="Landlord Property Detail" closeModal={closeModal}>
            <TextDetail label="Name" data={userDetail?.contactDetail?.name} />
            <TextDetail label="Email" data={userDetail?.contactDetail?.email} />
            <TextDetail label="Phone" data={userDetail?.contactDetail?.phone} />
            <TextDetail label="Role" data={userDetail?.contactDetail?.role} />
            <TextDetail label="Property Name" data={userDetail?.propertyName} />
            <TextDetail label="Property Size" data={`${userDetail?.propertySize?.size} ${userDetail?.propertySize?.unit}`} />
            <TextDetail label="Property Type" data={userDetail?.type} />
            <TextDetail label="Access Days" data={userDetail?.accessDays} />
            <TextDetail label="Availability Status" data={userDetail?.availableStatus} />
            <TextDetail label="Address" data={userDetail?.location?.address} />
            <TextDetail label="City, Country" data={`${userDetail?.location?.city?.name}, ${userDetail?.location?.country?.name}`} />
            <TextDetail label="Postcode" data={userDetail?.location?.postCode} />
            <TextDetail label="Amenities Provided" data={userDetail?.amenitiesProvided?.join(', ')} />
            <TextDetail label="Special Features or Unique Selling Points" data={userDetail?.specialFeatures} />
            <TextDetail label="Lease Duration" data={userDetail?.leaseDuration} />
            <TextDetail label="Pricing Structure" data={userDetail?.pricingStructure} />
            <TextDetail label="Discounts or Incentives for Coworking Tenants" data={userDetail?.discountsIncentive} />
            <TextDetail label="Which Coworking Spaces to Contact You" data={userDetail?.spacesContactYou} />
            <TextDetail label="List Your Property on TheCoworkingSpaces" data={userDetail?.listYourProperty} />
            <TextDetail label="Specific Requirements or Preferences for Tenants" data={userDetail?.specificRequirments} />
            <TextDetail label="Restrictions or Rules for Using the Space" data={userDetail?.restritions} />
            <TextDetail label="Additional Services or Support Provided by the Landlord" data={userDetail?.additionalServices} />
          </ModalContent>
        ) : null}
      </CustomModal>

      <ContentSection title="Landlord Property">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Landlord Property', active: true },]} />
        <Title bottomSpace={2}>Landlord Property</Title>
        <BoxSection>
          <p className="font-size-20 pink-color">Landlord Property : {properties?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Property Name</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th style={{ width: '140px' }} scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : properties?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.propertyName}</td>
                  <td>{item?.contactDetail?.name}</td>
                  <td>{item?.contactDetail?.email}</td>
                  <td>{item?.contactDetail?.phone}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {profile?.users_access?.includes("read") && (<div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>)}
                      {/* {profile?.users_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)} */}
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default LandlordProperty