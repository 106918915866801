import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteBlogs, getBlogCategory, getBlogs } from "../../store/apiSlice";
import CustomModal from "../../components/elements/Modal";
import DeleteModal from "../../components/DeleteModal";
import SearchDropdownFilter from "../../components/elements/SearchDropdownFilter";
import Button from "../../components/elements/Button";
import { ExternalLink, Pencil, Trash2 } from "lucide-react";
import moment from "moment";
import Title from "../../components/elements/Title";
import Breadcrumb from "../../components/elements/Breadcrumb";
import ContentSection from "../../components/elements/ContentSection";
import BoxSection from "../../components/elements/BoxSection";
import Table from "../../components/elements/Table";


const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [blogs, setblogs] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [load, setload] = useState(false);
  const [categories, setcategories] = useState(null);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("")
  }


  const blogDelete = async (id) => {
    try {
      await dispatch(deleteBlogs(id)).unwrap();
      setIsOpen(false);
      setFilteredData((prevCities) => prevCities?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Blogs = async () => {
    try {
      setload(true);
      setblogs(null);
      setFilteredData(null);
      const response = await dispatch(getBlogs()).unwrap();
      setblogs(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Blogs();
  }, []);

  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getBlogCategory()).unwrap();
      setcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Categories();
  }, []);


  const [categoryFilter, setcategoryFilter] = useState('');
  const [nameFilter, setnameFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = blogs?.filter(item => {
      const category = item?.categoryId?.name.toLowerCase();
      const name = item?.title?.toLowerCase();
      const creatorName = item?.createdBy?.name
      return (
        (categoryFilter === '' || category.includes(categoryFilter.toLowerCase())) &&
        (nameFilter === '' || name.includes(nameFilter.toLowerCase())) &&
        (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };

  const handleStatusChange = (status) => {
    if (selectedUsers.includes(status)) {
      setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
    } else {
      setselectedUsers(prevStatuses => [...prevStatuses, status]);
    }
  };

  const resetFilter = () => {
    setcategoryFilter('');
    setnameFilter('');
    setselectedUsers([]);
    setFilteredData(blogs)
    settoggle(false);
  };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType == "delete" ? (
          <DeleteModal closeModal={closeModal} onClick={() => blogDelete(id)} label="Blog" />
        ) : null}
      </CustomModal>

      <ContentSection title="Blogs">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'Blogs', active: true },]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Blogs</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e) => setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Name" />
                <label for="att-name">Name</label>
              </div>
              <div class="dropdown cw-select-dropdown mb-3">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {categoryFilter?.length > 0 ? categoryFilter : "Category"}
                </a>
                <ul class="dropdown-menu">
                  {categories?.length > 0 && categories?.map((c, i) => (
                    <li key={i}><a onClick={() => setcategoryFilter(c?.name)} className="dropdown-item">
                      {c?.name}
                    </a></li>
                  ))}
                </ul>
              </div>

              <div class="row mb-3 sp-input-filter">
                <label for="inputname" class="col-sm-2 col-form-label font-size-20 pink-color dm-font">Author</label>
                <div class="col-sm-10 d-flex gap-2 flex-wrap">
                  {uniqueCreators.map((creatorId, i) => (
                    <>
                      <input type="checkbox" class="btn-check" checked={selectedUsers.includes(creators[creatorId]?.name)} onChange={() => handleStatusChange(creators[creatorId]?.name)} id={`created-by-${i + 1}`} value={creators[creatorId]?.name} />
                      <label class="btn cw-select-btn" for={`created-by-${i + 1}`}>{creators[creatorId]?.name}</label>
                    </>
                  ))}

                </div>
              </div>
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button onClick={() => navigate("/add-blog")}>Add Blog</Button>
          </div>
        </div>
        <BoxSection>
          <p className="font-size-20 pink-color">Blogs : {filteredData?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Blog Name</th>
                <th scope="col">Category</th>
                <th scope="col">Excerpt</th>
                <th scope="col">Author</th>
                <th scope="col">Date</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.title}</td>
                  <td>{item?.categoryId?.name}</td>
                  <td>{item?.excerpt.substring(0, 60)}...</td>
                  <td>{item?.createdBy?.name}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <Link target="blank" to={`https://www.thecoworkingspaces.com/insights/${item?.categoryId?.name?.replace(/[\/\s]+/g, '-').toLowerCase()}/${item?.slug}`} className={`cw-action-icons l-blue-bg`}><ExternalLink width="18" /></Link>
                      <Link to={`/edit-blog/${item?._id}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </BoxSection>
      </ContentSection>
    </>
  )
}

export default Blogs