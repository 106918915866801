import React from 'react'

const Table = ({ children }) => {
    return (
        <div class="table-responsive">
            <table class="table cw-table table-borderless table-striped">
                {children}
            </table>
        </div>
    )
}

export default Table