import { Search, ImageDown } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import ChatInbox from './ChatInbox'
import { useDispatch, useSelector } from 'react-redux';
import { chatList, getProfile } from '../../store/apiSlice';
import { useLocation } from 'react-router-dom';
import { io } from "socket.io-client";
import ExistingChat from '../../components/Chat/ExistingChat';
import toast from 'react-hot-toast/headless';
const socket = io(process.env.REACT_APP_SOCKET_URL);

const Chat = () => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const location = useLocation()
    const dummyImage = "https://via.placeholder.com/50x50"
    const [listOfChat, setlistOfChat] = useState()
    const [currentChat, setcurrentChat] = useState(null);
    const [messageChat, setmessageChat] = useState(location?.state ? location?.state : null);
    const [filterExistingChats, setfilterExistingChats] = useState();
    const [startingToggle, setstartingToggle] = useState(false);
    const [searchExitingText, setsearchExitingText] = useState();


    const getAllChats = async () => {
        try {
            const response = await dispatch(chatList()).unwrap();
            setlistOfChat(response?.data)
            setfilterExistingChats(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log("listttttt", rejectedValueOrSerializedError);
        }
    };

    const handleSelectMessage = async (data) => {
        try {
            setcurrentChat(data)
            getAllChats()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        if (socket && profile?._id) {
            socket.emit('joinRoom', { userId: profile._id });
        }
    }, [socket, profile]);

    useEffect(() => {
        if (socket) {
            socket.on('updateChatList', ({ message, status }) => {
                console.log({ message, status }, "Update chat list");
                // toast.success(message)
                if(profile){
                    getAllChats();
                }
            });
        }
    }, [socket, profile]);

    const callMessage = () => {
        socket?.on("response", (data) => {
            if (data?.object_type == "unread_messages") {
                console.log(data)
            }
        });
    };


    useEffect(() => {
        getAllChats();
    }, [])


    useEffect(() => {
        callMessage();
        return () => socket?.off("response");
    }, [socket, currentChat]);

    const handleSearch = () => {
        if (!searchExitingText) {
            setfilterExistingChats(listOfChat);
        } else {
            const filters = filterExistingChats?.filter((i) => {
                const namematches = profile?._id == i?.sender_id?._id ? i?.receiver_id?.name : i?.sender_id?.name.toLowerCase().includes(searchExitingText?.toLowerCase());
                return namematches;
            });

            setfilterExistingChats(filters);
        }
    };


    useEffect(() => {
        handleSearch()
    }, [searchExitingText]);


    useEffect(() => {
        if (currentChat) {
            setmessageChat(null)
        }
    }, [currentChat]);

    return (
        <>
            <section className="sm-hero sm-messages-hero"></section>
            <section class="sm-chat-enqiries">
                <div class="container-fluid">

                    <div class="d-flex white-bg position-relative overflow-hidden">
                        <div class="col-12 col-lg-5 col-xl-3 border">
                            <div class="d-flex align-items-center gap-3 py-3 px-3 yellow-bg">
                                <div class="flex-grow-1 sm-search-chats">
                                    <Search width="18" height="18" className="sm-search-chats-icon" />
                                    <input type="text" class="form-control sm-search-chats-field rounded-0" placeholder="Search..." value={searchExitingText}
                                        onChange={(e) => setsearchExitingText(e.target.value)} />
                                </div>
                            </div>
                            <div className="sm-messages-list-container">
                                {filterExistingChats?.map((data, index) => (
                                    <ExistingChat key={index} profile={profile} data={data} handleSelectMessage={handleSelectMessage} currentChat={currentChat || messageChat} />
                                ))}
                            </div>
                        </div>
                        {currentChat || messageChat ? <div className="col-12 col-lg-7 col-xl-9 position-relative border">
                            <ChatInbox currentChat={currentChat} messageChat={messageChat} getAllChats={getAllChats} startingToggle={startingToggle} setstartingToggle={setstartingToggle} />
                        </div > : <div className="col-12 col-lg-7 col-xl-9  border d-flex align-items-center justify-content-center" style={{ height: "89vh" }}>
                            <h2 className="font-size-24 fw-bold yellow-color mb-0">Select Any Chat</h2>
                        </div >}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Chat 